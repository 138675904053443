import { Component } from '@angular/core';
import {LoaderService} from "../../services/loader.service";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  showLoader = false;
  constructor(private loaderService: LoaderService) {
    loaderService.$showLoader.subscribe(data => this.showLoader = data);
  }
}
