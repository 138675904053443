import {Component, OnInit} from '@angular/core';
import * as MobileDetect from 'mobile-detect';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit{

  mobileDetect = new MobileDetect(window.navigator.userAgent);
  id: string = '';
  navigationUrl = '';

  constructor(private router: Router) {
    this.id = this.create_UUID();
    localStorage.setItem('cdr_user_id', this.id);

    this.navigationUrl = this.router.url;
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      event instanceof NavigationEnd ? this.navigationUrl = event.url : null
    })
  }

  create_UUID(): string {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }




}
