import { CryptoLib } from '../Cil/lib/crypto';

export abstract class WalletDto {
  public address: string;
  protected privateKey: string;
  protected publicKey: string;
  protected keyFile: string;

  protected constructor(privateKey: string = null, keyFile: string = null) {
    this.privateKey = privateKey;
    this.keyFile = keyFile;
  }

  abstract encrypt(password: string): Promise<void>;

  abstract decrypt(password: string): Promise<void>;

  abstract sign(tran: any): Promise<string>;

  abstract getAddress(): string;

  abstract getKeyFileName(): string;

  getAddressInfo(): string {
    try {
      return JSON.parse(this.keyFile).address;
    }
    catch {
      return '';
    }
  }

  getPrivateKey(): string {
    return this.privateKey;
  }

  downloadKeyFile() {
    const data = new Blob([this.keyFile], {type: 'text/plain;charset=utf-8;'});
    const url = window.URL.createObjectURL(data);
    const linkElm = document.createElement('a');
    linkElm.setAttribute('href', url);
    linkElm.setAttribute('download', this.getKeyFileName());
    linkElm.click();
  }


  async checkHash(): Promise<boolean> {
    const file = JSON.parse(this.keyFile);
    const fileHash = file[this.hashPropertyName];
    if (!fileHash) {
      throw new Error(this.hashPropertyName + ' not found');
    }
    const forCheckHash = {};
    for (let property in file) {
      if (property !== this.hashPropertyName) {
        // @ts-ignore
        forCheckHash[property] = file[property];
      }
    }

    const calculatedHash = this.calculateHash(forCheckHash);
    return calculatedHash === fileHash;
  }

  protected calculateHash(object: any): string {
    return CryptoLib.createHash(JSON.stringify(object));
  }

  addHash(object: any) {
    let hash = this.calculateHash(object);
    let result = {
      ...object
    };
    result[this.hashPropertyName] = hash;

    return result;
  }

  get hasHash() {
    if (this.keyFile) {
      let parsed;
      try {
        parsed = JSON.parse(this.keyFile);
      } catch (e) {
        // old keystore format is plain text and doesn't support hash
        // return false in case of exception
        return false;
      }

      if (parsed.version == this.fileVersion && !parsed[this.hashPropertyName])
        throw new Error('KeyStore File incorrect');

      return !!parsed[this.hashPropertyName];
    }
    return false;
  }

  protected fileVersion = 1.1;

  hashPropertyName = 'ufc';
}
