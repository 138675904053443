import {Environment} from "./interface";

export const environment: Environment = {
  adminUrl: 'CDR-statistics',
  adminKey: 'fghjsd76GHFd567dhjkgs7t',
  production: false,
  apiKey: 'DSJGHFJK6457JHFHV54efhjFJYY5',
  // apiUrl: 'https://localhost:7042',
  apiUrl: 'https://cdr-api.dev20021.ubikiri.com',
  // apiUrl: 'https://cdr-api.cryptoreceipt.io',
  explorerUri: 'https://test-explorer.ubikiri.com',
  // explorerUri: 'https://explorer.ubikiri.com',
  pairs: [{fromCoin : 'ETH', fromSymbol: '', toCoin: 'LAW', toSymbol: 'UBWF'},
    {fromCoin : 'ETH', fromSymbol: 'UBX', toCoin: 'LAW', toSymbol: ''},
    {fromCoin : 'ETH', fromSymbol: 'UBX', toCoin: 'LAW', toSymbol: 'CDF'},
    {fromCoin : 'BSC', fromSymbol: 'UBX', toCoin: 'LAW', toSymbol: ''},
    {fromCoin : 'OP', fromSymbol: 'UBX', toCoin: 'LAW', toSymbol: ''},
    {fromCoin : 'LAW', fromSymbol: '', toCoin: 'ETH', toSymbol: 'UBX'},
    {fromCoin : 'LAW', fromSymbol: '', toCoin: 'BSC', toSymbol: 'UBX'},
    {fromCoin : 'LAW', fromSymbol: '', toCoin: 'OP', toSymbol: 'UBX'},
    {fromCoin : 'LAW', fromSymbol: 'CDF', toCoin: 'ETH', toSymbol: 'UBX'}],
  // pairs: [{fromCoin : 'ETH', fromSymbol: 'UBX', toCoin: 'LAW', toSymbol: ''},
  //   {fromCoin : 'LAW', fromSymbol: '', toCoin: 'ETH', toSymbol: 'UBX'},
  //   {fromCoin : 'ETH', fromSymbol: 'UBXE', toCoin: 'LAW', toSymbol: 'LSE'},
  //   {fromCoin : 'LAW', fromSymbol: 'LSE', toCoin: 'ETH', toSymbol: 'UBXE'},
  //   {fromCoin : 'ETH', fromSymbol: 'UBXM', toCoin: 'LAW', toSymbol: 'MMM'},
  //   {fromCoin : 'LAW', fromSymbol: 'MMM', toCoin: 'ETH', toSymbol: 'UBXM'},
  //   {fromCoin : 'ETH', fromSymbol: 'UBCDR', toCoin: 'LAW', toSymbol: 'CDR'},
  //   {fromCoin : 'LAW', fromSymbol: 'CDR', toCoin: 'ETH', toSymbol: 'UBCDR'},
  //   {fromCoin : 'ETH', fromSymbol: 'UBSN', toCoin: 'LAW', toSymbol: 'UBSN'},
  //   {fromCoin : 'LAW', fromSymbol: 'UBSN', toCoin: 'ETH', toSymbol: 'UBSN'},
  //   {fromCoin : 'ETH', fromSymbol: 'UBXP', toCoin: 'LAW', toSymbol: 'WAF'},
  //   {fromCoin : 'LAW', fromSymbol: 'WAF', toCoin: 'ETH', toSymbol: 'UBXP'},
  //   {fromCoin : 'ETH', fromSymbol: 'UBX4T', toCoin: 'LAW', toSymbol: 'TKS'},
  //   {fromCoin : 'LAW', fromSymbol: 'TKS', toCoin: 'ETH', toSymbol: 'UBX4T'},
  //   {fromCoin : 'ETH', fromSymbol: 'UBXCF', toCoin: 'LAW', toSymbol: 'CDF'},
  //   {fromCoin : 'LAW', fromSymbol: 'CDF', toCoin: 'ETH', toSymbol: 'UBXCF'}],
  displaySymbolNames: [{symbol: 'CDF', displayName: 'UBPAY'},
    {symbol: 'CDR', displayName: 'UBCDR'},
    {symbol: 'CIF', displayName: 'UBINF'},
    {symbol: 'ESC', displayName: 'UBESC'},
    {symbol: 'LSE', displayName: 'UBXE'},
    {symbol: 'MMM', displayName: 'UBXM'},
    {symbol: 'SIN', displayName: 'UBSIN'},
    {symbol: 'STK', displayName: 'UBSMK'},
    {symbol: 'TKS', displayName: 'UBX4T'},
    {symbol: 'VGS', displayName: 'UBXCV'},
    {symbol: 'WAF', displayName: 'UBWF'}],
  videoId: '3E2tab5wqD0',
  identityApi: "https://identity.dev20021.ubikiri.com",
  projectCode: "cdr",
  supportChat: "https://chat-api.dev20021.ubix.network/api"
}
