<div class="access-modal">
  <button class="close" (click)="closeClick()">
    <mat-icon>close</mat-icon>
  </button>

  <ng-container *ngIf="isAccessTypeSelected; then isInputPassword ? keystoreTemplate : privateKeyTemplate; else accessOptionsTemplate">
  </ng-container>
</div>

<ng-template #accessOptionsTemplate>
  <div class="content">
    <h2>Sign transaction</h2>
    <p>Choose your keystore file or enter your private key and click «Continue»</p>
    <div class="form-field" [ngClass]="{'selected': selectedAccessType.isSelected(walletAccessType.KeyStore)}">
      <div class="type-icon file-icon"></div>
      <button type="button" class="button stroke key-file" (click)="selectedAccessType.toggle(walletAccessType.KeyStore)">Keystore File</button>
      <mat-icon>check_circle</mat-icon>
    </div>
    <div class="form-field" [ngClass]="{'selected': selectedAccessType.isSelected(walletAccessType.PrivateKey) }">
      <div class="type-icon password-icon"></div>
      <button type="button" class="button stroke key-password" (click)="selectedAccessType.toggle(walletAccessType.PrivateKey)">Private Key</button>
      <mat-icon>check_circle</mat-icon>
    </div>
    <button type="button" mat-stroked-button color="primary" class="btn" (click)="getAccess()" [disabled]="selectedAccessType.isEmpty()">Continue</button>
    <input type="file" name="files" style="display: none" #fileUploader="" (change)="onFileChange($event)">
  </div>

</ng-template>

<ng-template #keystoreTemplate>
  <app-access-with-keystore (password)="accessWithKeyStore($event)" [error]="error"></app-access-with-keystore>
</ng-template>

<ng-template #privateKeyTemplate>
  <app-access-with-key (key)="accessWithPrivateKey($event)" [error]="error"></app-access-with-key>
</ng-template>
