<div class="content">
  <h2>Password</h2>
  <form class="form">
    <mat-form-field class="form-field">
      <button class="icon" type="button" (click)="showPwd = !showPwd", [ngClass]="showPwd ? 'visibility' : 'visibility off'"></button>
      <input matInput [type]="showPwd ? 'text' : 'password'"  placeholder="Enter your password" formControlName="password">
    </mat-form-field>
    <mat-error *ngIf="error">
      <span>{{error}}</span>
    </mat-error>
    <button type="submit" [ngClass]="{disabled: passwordForm.invalid}">Continue</button>

  </form>
</div>
