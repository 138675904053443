import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule, Routes} from "@angular/router";
import { AdminLayoutComponent } from './shared/components/admin-layout/admin-layout.component';
import { LoginPageComponent } from './login-page/login-page.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AuthService} from "./shared/services/auth.service";
import {environment} from "../../environments/environment";
import {CdrPageComponent} from "./cdr-page/cdr-page.component";
import {LoaderComponent} from "./shared/components/loader/loader.component";
import {ErrorFormComponent} from "./shared/components/error-form/error-form.component";
import {MaterialAngularModule} from "../../material.module";
import {CdrPageOptionsComponent} from "./cdr-page-options/cdr-page-options.component";
import {MAT_DATE_LOCALE} from "@angular/material/core";



const routes: Routes = [
  {
    path: '', component:AdminLayoutComponent, children: [
      { path: '', redirectTo: '/' + environment.adminUrl + '/login', pathMatch: 'full' },
      { path: 'login', component: LoginPageComponent },
      { path: 'cdr', component: CdrPageComponent },
      { path: 'cdr-options', component: CdrPageOptionsComponent }
    ]
  }
]
@NgModule({
  declarations: [
    AdminLayoutComponent,
    LoginPageComponent,
    CdrPageComponent,
    CdrPageOptionsComponent,
    LoaderComponent,
    ErrorFormComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    FormsModule,
    MaterialAngularModule
  ],
  exports:[
    RouterModule
  ],
  providers:[
    AuthService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ]
})
export class AdminModule {

}
