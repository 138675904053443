<div *ngIf="!mobileDetect.phone()">
  <div class="row block-1">

    <div class="rect-310"></div>
    <div class="rect-317"></div>


    <div class="rect-306"></div>
    <div class="rect-307"></div>

    <div class="rect-311"></div>

    <div class="rect-312"></div>
    <div class="rect-316"></div>
    <div class="rect-313"></div>



    <div class="cdr col-8 offset-2">
      <span class="cdr-color-1">C</span>rypto <span class="cdr-color-2">D</span>epositary <span class="cdr-color-3">R</span>eceipts
    </div>

    <div class="block-2 col-6 offset-3">
      <div class="text">
        The service is designed to solve the difficulties mentioned<br/>
        by aiming to make the exchange procedure<br/>
        <span class="text-color-1">the simplest way possible.</span>
      </div>
    </div>

    <div class="row block-2">
      <button class="button-exchange col" (click)="goExchange()">
        <span class="button-exchange-text">Use CDR</span>
      </button>
    </div>

    <div class="row block-2">
      <div class="text-block col-7">
        <p>At the moment, there are dozens of actively working blockchain ecosystems.
          All of them are isolated and communicate by the means of centralized exchanges.
          In turn, exchanges dictate their own conditions and depend on industry regulations.
          Many platforms are developing their own decentralized exchanges,
          but only for the movement of tokens within one ecosystem</p>
<!--        <p>At the moment, there are dozens of actively working blockchain ecosystems.<br/>-->
<!--          All of them are isolated and communicate by the means of centralized exchanges.<br/>-->
<!--          In turn, exchanges dictate their own conditions and depend on industry regulations.<br/>-->
<!--          Many platforms are developing their own decentralized exchanges,-->
<!--          but only for the movement of tokens within one ecosystem</p>-->
      </div>
    </div>
    <div class="row block-4">
      <div class="how-text col">How our <span class="how-text-bold">solution</span> works:</div>
    </div>

    <div class="row block-3">

      <div class="video" fxLayoutAlign="center center">
        <div class="video-value">
        </div>
        <div class="video-background" [ngClass]="{'video-playing':playYoutube && !youtubeLoading}">
          <img class="cdr-video-pic" src="/assets/pics/cdr_video_pic.png">
        </div>
        <div class="play-video">
          <div fxLayoutAlign="center center">
            <youtube-player *ngIf="playYoutube"
                            [videoId]="videoId"
                            [width]="1222"
                            [height]="518"
                            [playerVars]="playerVars"
                            (ready)="readyYT($event)"

                            fxLayoutAlign="center center"
            ></youtube-player>
            <div fxLayoutAlign="center center" style="position: absolute" *ngIf="youtubeLoading">
<!--              <mat-spinner></mat-spinner>-->
            </div>
            <button *ngIf="!playYoutube"
                    style="position: absolute" mat-icon-button fxLayoutAlign="center center"
                    (click)="playVideo()">
              <img style="width: 100px; height: 100px;" src="/assets/icons/video_play.svg" alt="">
            </button>
          </div>
        </div>
      </div>


<!--      <div [ngClass]="{'ellipse': !mobileDetect.phone(), 'ellipse-mobile': mobileDetect.phone()}"></div>-->
<!--      <div [ngClass]="{'video-item': !mobileDetect.phone(), 'video-item-mobile': mobileDetect.phone()}" class="col">-->
<!--        <iframe [ngClass]="{'youtube': !mobileDetect.phone(), 'youtube-mobile': mobileDetect.phone()}"-->
<!--                src="https://www.youtube.com/embed/cwRo5L1irAQ">-->
<!--        </iframe>-->
<!--      </div>-->
    </div>


  </div>
</div>

<div *ngIf="mobileDetect.phone()">
  <div class="row">
    <div class="ellipse-1" [ngClass]="{'ellipse-1-mobile': mobileDetect.phone()}">
      <div class="ellipse-2" [ngClass]="{'ellipse-2-mobile': mobileDetect.phone()}"></div>
    </div>
    <div class="ellipse-3" [ngClass]="{'ellipse-3-mobile': mobileDetect.phone()}">
      <div class="ellipse-4" [ngClass]="{'ellipse-4-mobile': mobileDetect.phone()}"></div>
    </div>
    <div class="ellipse-5" [ngClass]="{'ellipse-5-mobile': mobileDetect.phone()}">
      <div class="ellipse-6" [ngClass]="{'ellipse-6-mobile': mobileDetect.phone()}"></div>
    </div>
  </div>
  <div class="row">
    <div class="cdr-mobile col">
      <span class="cdr-color-1">Crypto </span>
      <span class="cdr-color-2">Depositary </span>
      <span class="cdr-color-3">Receipts</span>
    </div>
  </div>

  <div class="row block-2">
    <div class="text col text-mobile">Service is designed to solve the difficulties mentioned by aiming to make the exchange procedure the simplest way possible.</div>
<!--      <span class="text-color-1"></span>-->

  </div>

  <div class="row block-22">
    <button class="button-exchange col button-exchange-mobile" (click)="goExchange()">
      <span class="button-exchange-text button-exchange-text-mobile">Use CDR</span>
    </button>
  </div>

  <div class="row block-2">
    <div class="text-block text-block-mobile-1 col-10 offset-1">
      <p class="text-block-mobile-bold">Problems now:</p>
      <p class="text-block-mobile">At the moment, there are dozens of actively working blockchain ecosystems.
        All of them are isolated and communicate by the means of centralized exchanges.
        In turn, exchanges dictate their own conditions and depend on industry regulations.
        Many platforms are developing their own decentralized exchanges, but only for the
        movement of tokens within one ecosystem.</p>
    </div>
  </div>
  <div class="row block-2">
    <div class="how-text col how-text-mobile">How our <span class="how-text-bold">solution</span> works:</div>
  </div>

  <div class="row block-2">
    <div class="ellipse-mobile"></div>
    <div class="video-item-mobile col">
      <iframe class="youtube-mobile"src="https://www.youtube.com/embed/3E2tab5wqD0"></iframe>
    </div>
  </div>


</div>






