import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";

@Component({
  selector: 'app-access-with-key',
  templateUrl: './access-with-key.component.html',
  styleUrls: ['./access-with-key.component.scss']
})
export class AccessWithKeyComponent {
  showKey = false;

  @Input() error: string;
  @Output() key = new EventEmitter<string>();

  keyForm = this.formBuilder.group({
    key: ['', [Validators.required, Validators.minLength(64)]]
  });

  constructor(private formBuilder: FormBuilder) {
  }

  onSubmit() {
    if (this.keyForm.invalid) {
      return;
    }
    this.key.emit(this.keyForm.value.key);
  }
}
