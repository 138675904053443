<div class="container">
  <h1 class="container-right-header">Swap</h1>
  <div *ngIf="!pair" class="text">Please, choose pair to swap from the order list</div>
  <div *ngIf="pair">
    <p class="text">Here you can un-wrap your {{pair.fromDisplayName}} and receive {{pair.toDisplayName}}</p>
    <form [formGroup]="swapAddress" (submit)="createSwap()" >
      <mat-form-field class="input-field">
        <mat-label>Wallet to receive {{pair.toDisplayName}}</mat-label>
        <input type="text" matInput formControlName="walletAddress">
<!--        <mat-error *ngIf="swapAddress.hasError('address') && !swapAddress.hasError('required')">-->
<!--          Please enter a valid address-->
<!--        </mat-error>-->
        <mat-error *ngIf="swapAddress.controls.walletAddress.hasError('required')">
          Wallet address is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="!swapAddress.controls.walletAddress.hasError('required') &&
                          (swapAddress.controls.walletAddress.hasError('minlength') ||
                          swapAddress.controls.walletAddress.hasError('swapAddress'))">
          Invalid address.
        </mat-error>
      </mat-form-field>
      <button submit color="primary" class="btn">Continue</button>
    </form>

  </div>

</div>


