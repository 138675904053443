<mat-card>
  <mat-card-content>
    <h4>Sign In</h4>

    <form [formGroup]="loginForm">

      <mat-form-field appearance="outline">
        <mat-label>Enter your email</mat-label>
        <input id="email" formControlName="email" matInput placeholder="email@example.com" required>
        <mat-error *ngIf="loginForm.get('email')?.touched && loginForm.get('email')?.invalid">{{getEmailErrorMessage()}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Enter your password</mat-label>
        <input id="password" formControlName="password" matInput [type]="hide ? 'password' : 'text'" required>
        <button mat-icon-button matSuffix (click)="hide = !hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="loginForm.get('password')?.touched && loginForm.get('password')?.invalid">{{getPasswordErrorMessage()}}</mat-error>
      </mat-form-field>

    </form>


    <button mat-stroked-button color="primary" (click)="submit()" [disabled]="loginForm.invalid">Login</button>


  </mat-card-content>
</mat-card>
