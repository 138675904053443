<div class="content">
  <h2>Sign transaction</h2>
  <form class="form" [formGroup]="keyForm" (ngSubmit)="onSubmit()">
    <mat-form-field class="form-field">
      <button type="button" (click)="showKey = !showKey" [ngClass]="showKey ? 'visibility' : 'visibility off'"></button>
      <input matInput [type]="showKey ? 'text' : 'password'" placeholder="Enter Private Key" formControlName="key">
    </mat-form-field>
    <mat-error *ngIf="error">
      <span>{{error}}</span>
    </mat-error>
    <button type="submit" mat-stroked-button color="primary" class="btn" [disabled]="keyForm.invalid">Generate</button>
  </form>
</div>
