<app-loader></app-loader>
<mat-toolbar color="primary">
  <button *ngIf="isAuth" mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" matTooltip="Menu" [matMenuTriggerFor]="belowMenu">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #belowMenu="matMenu" yPosition="below">
    <button mat-menu-item [routerLink]="[link, 'cdr']"><mat-icon>flash_on</mat-icon>CDR</button>
    <mat-divider></mat-divider>
    <button mat-menu-item [routerLink]="[link, 'cdr-options']"><mat-icon>wb_sunny</mat-icon>CDR Options</button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="logout()"><mat-icon>exit_to_app</mat-icon>Logout</button>
  </mat-menu>
  <span>Analytics</span>

  <span class="example-spacer"></span>
  <button *ngIf="isAuth" mat-icon-button class="example-icon" aria-label="Example icon-button with share icon" matTooltip="CDR" [routerLink]="[link, 'cdr']">
    <mat-icon>flash_on</mat-icon>
  </button>
  <span *ngIf="isAuth" class="example-divider"></span>
  <button *ngIf="isAuth" mat-icon-button class="example-icon" aria-label="Example icon-button with share icon" matTooltip="CDR Options" [routerLink]="[link, 'cdr-options']">
    <mat-icon>wb_sunny</mat-icon>
  </button>
  <span *ngIf="isAuth" class="example-divider"></span>
  <button *ngIf="isAuth" mat-icon-button class="example-icon" aria-label="Example icon-button with share icon" matTooltip="Logout" (click)="logout()">
    <mat-icon>exit_to_app</mat-icon>
  </button>
</mat-toolbar>

<div class="container center admin-container">
  <router-outlet></router-outlet>
</div>


