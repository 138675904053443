import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
  CreateTransactionRequest,
  TransactionClient,
  TransitClient, TransitWalletDto
} from "../../../api/api.client.generated";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {SpinnerService} from "../../../services/spinner.service";
import {WalletQrComponent} from "../wallet-qr/wallet-qr.component";
import {MatDialog} from "@angular/material/dialog";
import {MatTooltip} from "@angular/material/tooltip";
import {PairService} from "../../../services/pair.service";

@Component({
  selector: 'app-send',
  templateUrl: './send.component.html',
  styleUrls: ['./send.component.scss']
})
export class SendComponent implements OnInit {
  form = this.formBuilder.group({
    amount: [0,
      [Validators.required, Validators.min(0)]
    ],
    fromAddress: [{
      value: '',
      disabled: true
    }, Validators.required],
    transitAddress: [{
      value: '',
      disabled: true
    }, Validators.required]
  });

  transitWallet: TransitWalletDto;
  readonly defaultTooltipText = 'Copy Address Wallet';
  tooltipText = this.defaultTooltipText;
  @ViewChild('matTooltip') matTooltip: MatTooltip;

  constructor(protected formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private transactionClient: TransactionClient,
              private transitClient: TransitClient,
              private router: Router,
              private spinnerService: SpinnerService,
              private dialog: MatDialog,
              private pairService: PairService) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) =>  {
      const transitWallet = params.get('transitWallet');
      this.transitClient
        .getRateByTransitWallet(transitWallet)
        .subscribe(data => {
          this.transitWallet = data;
          this.pairService.$currentPair.next(data.pair);
          this.pairService.$holdPair.next(true);
          this.initForm();
        });
    })
  }

  initForm() {
    this.form = this.formBuilder.group({
      amount: [this.transitWallet ? this.transitWallet.amount : 0,
        [Validators.required, Validators.min(this.transitWallet ? this.transitWallet.rate.minAmountWithFee : 0)]
      ],
      fromAddress: [{
        value: this.transitWallet ? this.transitWallet.fromAddress : '',
        disabled: true
      }, Validators.required],
      transitAddress: [{
        value: this.transitWallet ? this.transitWallet.address : '',
        disabled: true
      }, Validators.required]
    })
  }

  createTransaction() {
    if (!this.form.valid) {
      return;
    }

    this.spinnerService.$showSpinner.next(true);

    this.transactionClient.create({
      amount: this.form.value.amount,
      address: this.form.value.fromAddress,
      transitAddress: this.form.value.transitAddress
    } as CreateTransactionRequest).subscribe(data => {
      this.spinnerService.$showSpinner.next(false);
      this.router.navigate([`send-confirm/${data.transitAddress}`]).then()
    })
  }

  showQR(): void {
    const dialogRef = this.dialog.open(WalletQrComponent, {
      width: '440px',
      panelClass: 'copyQR',
      data: {hashAddress: this.transitWallet.address, name: 'Swap address'}
    });
  }

  copyClick() {
    this.matTooltip.hide();
    this.tooltipText = 'Copied Address Wallet';
    this.matTooltip.show();
  }
}
