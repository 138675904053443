import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {User} from "../shared/interfaces/User";
import {AuthService} from "../shared/services/auth.service";
import {Router} from "@angular/router";
// import {SocialNetworkClient} from "../../home-page/components/SocialNetworkClient";
import {environment} from "../../../environments/environment";
import {LoaderService} from "../shared/services/loader.service";
import {MatDialog} from "@angular/material/dialog";
import {ErrorFormComponent} from "../shared/components/error-form/error-form.component";


@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit{
  loginForm: FormGroup
  hide = true
  link: string = '/' + environment.adminUrl;
  token: string | undefined;

  constructor(
    private authService: AuthService,
    private loaderService: LoaderService,
    private router: Router,
    // protected socialNetworkClient: SocialNetworkClient,
    public dialog: MatDialog) {

    this.token = undefined;

    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)])
    })
  }

  ngOnInit() {
  }

  submit() {
    if (this.loginForm.invalid) {
      console.log("Error! Invalid form data!")
      this.showError("Invalid form data!");
      return;
    }

    const user: User = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
      returnSecureToken: true
    }
    //this.loaderService.$showLoader.next(true);

    if (user.email === 'admin@cdr.com' && user.password === 'admin@cdr.com') {
      //yes
      this.authService.$isAuth.next(true);
      this.authService.$isAdmin.next(true);
      this.loginForm.reset()
      this.router.navigate([this.link, 'cdr'])
    } else {
      //no
      this.authService.$isAuth.next(false);
      this.authService.$isAdmin.next(false);
      console.log('User not found!')
      this.showError("User not found!", false);
    }

    // this.socialNetworkClient.adminStart(this.authService.$userId.value, user).subscribe(result => {
    //   // send start token generation signal to server
    //
    //   // console.log('result!!!', result);
    //
    //   this.authService.$isAuth.next(result.isAuth!);
    //   this.authService.$isAdmin.next(result.isAdmin!);
    //   if (user.returnSecureToken) {
    //     this.authService.$userId.next(result.token!);
    //   }
    //
    //   if (result.isAuth!) {
    //     this.loginForm.reset()
    //     this.router.navigate([this.link, 'dashboard'])
    //   } else {
    //     // SHOW MESSAGE
    //     console.log('User not found!')
    //     this.showError("User not found!", false);
    //   }
    //   this.loaderService.$showLoader.next(false);
    //
    // },() => {
    //   this.loaderService.$showLoader.next(false);
    //
    //   this.authService.$isAuth.next(false);
    //   this.authService.$isAdmin.next(false);
    //   console.log("Error! Cannot receive data from server!")
    //   this.showError("Cannot receive data from server!");
    // });

  }

  getEmailErrorMessage() {
    if (this.loginForm.get('email')?.hasError('required')) {
      return 'You must enter E-Mail';
    }
    return this.loginForm.get('email')?.hasError('email') ? 'Not a valid E-Mail' : '';
  }

  getPasswordErrorMessage() {
    if (this.loginForm.get('password')?.hasError('required')) {
      return 'You must enter Password';
    }
    return this.loginForm.get('password')?.hasError('minlength') ? 'Password min length is 8' : '';
  }

  showError(message: String, isError: boolean = true): void {
    this.dialog.open(ErrorFormComponent, {
      width: '440px',
      data: {'message': message, 'isError': isError}
    });
  }

}
