<div *ngIf="walletDto">
  <div *ngIf="!mobileDetect.phone()" class="items details">
    <div class="row">
      <div class="col">
    <h1 class="container-right-header">Un-wrap {{walletDto.pair.fromDisplayName}} into {{walletDto.pair.toDisplayName}}</h1>

    <p class="text">
      Here you can un-wrap your <span>{{(walletDto.amount + walletDto.rate.fee) | number:'1.0-5'}} {{walletDto.pair.fromDisplayName}}</span><br/>
      and receive <span>{{(walletDto.amount * walletDto.rate.rate) | number:'1.0-5'}} {{walletDto.pair.toDisplayName}}</span>,<br/>
      which will be credited to your wallet: <span>{{walletDto.swapAddress}}</span>
    </p>
    <div *ngIf="showInterface">
      <div *ngIf="showAddress">
        <p class="text">To un-wrap please send <span>{{(walletDto.amount + walletDto.rate.fee) | number:'1.0-5'}} {{walletDto.pair.fromDisplayName}}</span> to this address:</p>

        <form class="example-form" [formGroup]="sendForm" *ngIf="sendForm" (submit)="createTransaction()">
<!--          <mat-form-field class="input-field">-->
            <!--      <mat-label>Wallet to</mat-label>-->
          <div>
            <input type="text" class="input-field-input" matInput formControlName="transitAddress">
<!--          </mat-form-field>-->
          <!--suppress AngularInvalidTemplateReferenceVariable -->
          <button class="copy"
                  ngxClipboard
                  [cbContent]="walletDto.address"
                  (click)="copyClick()"
                  (mouseenter)="showTooltip()"
                  (mouseleave) ="hideTooltip()"
          >
            <img src="/assets/icons/copy.svg">
          </button>
          <span #myTooltip="matTooltip"
               [matTooltip]="tooltipText"
                matTooltipPosition="right" class="tooltip-span">

          </span>
          </div>
        </form>

        <button class="qr"
                ngxClipboard
                [cbContent]="walletDto.address"
                matTooltip="Show QR Code"
                matTooltipPosition="right"
                (click)="showQR()"
        >
          <img src="/assets/icons/qr-code.svg">
        </button>

        <div class="row-info min-amount">
          <img class="info-img" src="/assets/icons/info.svg">
          <span>
            This is a single-use address that can only be used once.
            <br/>
            <br/>
            Temporary address is valid only for <b>{{timeResult}}!</b>
            <br/>
          </span>
        </div>

        <div class="row-info status">
          <div class="status">Current status: <span>{{swapStatus}}</span> (refresh in {{statusTimer | number:'2.0-3'}} sec)</div>
        </div>
      </div>
      <div *ngIf="!showAddress">
        <p class="text"><b>Temporary address is expired!<br/>Please, generate new address for exchange!</b></p>
      </div>
    </div>
    <button class="close-order" (click)="closeSwap()">
      <span class="button-text">Close</span>
    </button>
        </div>
      </div>
  </div>

  <div *ngIf="mobileDetect.phone()">
    <div class="row">
      <div class="col">
        <h1 class="container-right-header container-right-header-mobile">Un-wrap {{walletDto.pair.fromDisplayName}} into {{walletDto.pair.toDisplayName}}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p class="text-mobile">
          Here you can un-wrap your<br/>
          <span>{{(walletDto.amount + walletDto.rate.fee) | number:'1.0-5'}} {{walletDto.pair.fromDisplayName}}</span><br/>
          and receive<br/>
          <span>{{(walletDto.amount * walletDto.rate.rate) | number:'1.0-5'}} {{walletDto.pair.toDisplayName}}</span>,<br/>
          which will be credited to your wallet:<br/>
          <span>{{walletDto.swapAddress}}</span>
        </p>
      </div>
    </div>
    <div *ngIf="showInterface">
      <div *ngIf="showAddress">
        <div class="row">
          <div class="col">
            <p class="text-mobile">To un-wrap please send <span>{{(walletDto.amount + walletDto.rate.fee) | number:'1.0-5'}} {{walletDto.pair.fromDisplayName}}</span> to this address:</p>
          </div>
        </div>

        <qrcode class="qrcode-mobile" [qrdata]="walletDto.address" [width]="159"></qrcode>
        <div class="row">
          <div class="col">
            <p class="text-mobile"><span>{{walletDto.address}}</span></p>
          </div>
        </div>

        <div class="row-info min-amount">
          <img class="info-img" src="/assets/icons/info.svg">
          <span>
            This is a single-use address that can only be used once.
            <br/>
            <br/>
            Temporary address is valid only for <b>{{timeResult}}!</b>
            <br/>
          </span>
        </div>

        <div class="row-info status">
          <div class="status">Current status: <span>{{swapStatus}}</span> (refresh in {{statusTimer | number:'2.0-3'}} sec)</div>
        </div>
      </div>
      <div *ngIf="!showAddress">
        <p class="text-mobile"><b>Temporary address is expired!<br/>Please, generate new address for exchange!</b></p>
      </div>
    </div>
    <button class="close-order close-order-mobile" (click)="closeSwap()">
      <span class="button-text">Close</span>
    </button>
  </div>
</div>

