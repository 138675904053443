import {Component, Input, OnInit} from '@angular/core';
import {PairsClient, PairDto} from "../../../api/api.client.generated";
import {PairService} from "../../../services/pair.service";
import {SpinnerService} from "../../../services/spinner.service";
import {MatDialog} from "@angular/material/dialog";
import {ErrorFormComponent} from "../../../components/error-form/error-form.component";
import {environment} from "../../../../environments/environment";
import * as MobileDetect from 'mobile-detect';

@Component({
  selector: 'app-pairs',
  templateUrl: './pairs.component.html',
  styleUrls: ['./pairs.component.scss']
})
export class PairsComponent implements OnInit {

  @Input() navigationUrl = '';

  mobileDetect = new MobileDetect(window.navigator.userAgent);
  pairs: PairDto[] = [];
  currentPair: PairDto;
  isHold = false;
  constructor(private apiClient: PairsClient,
              private pairService: PairService,
              private spinnerService: SpinnerService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.initPairs();
    this.pairService.$holdPair.subscribe(isHold => this.isHold = isHold);
    this.pairService.$currentPair.subscribe(pair => this.currentPair = pair);
  }

  private initPairs() {
    this.spinnerService.$showSpinner.next(true);
    this.pairs = [];
    this.apiClient.getPairs().subscribe(data => {
      for (const datum of environment.pairs) {
        const findEl = data.find(value =>
          (value.fromCoin === datum.fromCoin && value.fromSymbol === datum.fromSymbol && value.toCoin === datum.toCoin && value.toSymbol === datum.toSymbol)
        )
        if (findEl) {
          // Check if need to change display name
          const fromCoin = (findEl.fromCoin === 'LAW') ? 'Native' : findEl.fromCoin;
          const toCoin = (findEl.toCoin === 'LAW') ? 'Native' : findEl.toCoin;

          for (const displayNames of environment.displaySymbolNames) {
            if (findEl.fromSymbol === displayNames.symbol) {
              findEl.fromDisplayName = displayNames.displayName + '(' + fromCoin + ')';
            }
            if (findEl.toSymbol === displayNames.symbol) {
              findEl.toDisplayName = displayNames.displayName + '(' + toCoin + ')';
            }
          }
          findEl.displayName = findEl.fromDisplayName + ' -> ' + findEl.toDisplayName;
          // findEl.displayName = 'UBPAY(Native)' + ' -> ' + 'UBPAY(Native)';
          this.pairs.push(findEl);
        }
      }
      this.spinnerService.$showSpinner.next(false);
    },  error => {
      this.spinnerService.$showSpinner.next(false);
      this.showError("Error while receive exchange list from server!");
    });
  }

  get pairsDisplayText(): string[] {
    return this.pairs.map(x => x.displayName);
  }

  onPairClick(val: string) {
    console.log(val);
    // this.spinnerService.$showSpinner.next(true);
    const pair = this.pairs.find(x => x.displayName == val);
    this.pairService.$currentPair.next(pair);
  }

  onPairEventClick(_event: Event) {
    const val = (_event.target as HTMLInputElement).value
    // this.spinnerService.$showSpinner.next(true);
    if (val === 'Select') {
      this.pairService.$currentPair.next(null);
    }
    else {
      const pair = this.pairs.find(x => x.displayName == val);
      this.pairService.$currentPair.next(pair);
    }
  }


  isCurrentPair(val: string): boolean {
    return this.currentPair && val === this.currentPair.displayName;
  }

  refreshList() {
    this.currentPair = null;
    this.initPairs();
    this.pairService.$currentPair.next(null);
  }

  showError(message: String): void {
    this.dialog.open(ErrorFormComponent, {
      width: '440px',
      data: message
    });
  }
}
