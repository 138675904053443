<div class="container">
  <h1 class="container-right-header">Send Confirmation</h1>
  <form class="example-form" [formGroup]="sendForm" *ngIf="sendForm" (submit)="openAccessWalletDialog()">
    <mat-form-field class="input-field">
      <mat-label>Amount</mat-label>
      <input type="text" matInput formControlName="amount">
    </mat-form-field>

    <mat-form-field fxLayout="row" class="input-field">
      <mat-label>Wallet from</mat-label>
      <input type="text" matInput formControlName="transactionAddress">
    </mat-form-field>

    <mat-form-field fxLayout="row" class="input-field">
      <mat-label>Wallet to</mat-label>
      <input type="text" matInput formControlName="transitAddress">
    </mat-form-field>
    <button mat-stroked-button type="submit" color="primary" class="btn">Continue</button>
  </form>
</div>
