import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TransactionClient, TransactionDto} from "../../../api/api.client.generated";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {AccessToWalletComponent} from "../../access-to-wallet/access-to-wallet/access-to-wallet.component";
import {WalletDto} from "../../../models/Wallets/WalletDto";
// import {mergeMap, Observable} from "rxjs";
// import {CilTransactionHelper} from "../../../models/Cil/CilTransaction";

@Component({
  selector: 'app-send-confirm',
  templateUrl: './send-confirm.component.html',
  styleUrls: ['./send-confirm.component.scss']
})
export class SendConfirmComponent implements OnInit {

  sendForm: FormGroup;
  transaction: TransactionDto;

  constructor(private formBuilder: FormBuilder,
              private transactionClient: TransactionClient,
              private route: ActivatedRoute,
              protected dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) =>  {
      const transitWallet = params.get('transitWallet');
      this.transactionClient
        .getTransactionByTransitAddress(transitWallet)
        .subscribe(data => {
          this.transaction = data;
          this.initForm();
        });
    })
  }

  initForm() {
    this.sendForm = this.formBuilder.group({
      amount: [{
        value: this.transaction ? this.transaction.amount : 0,
        disabled: true
      }, Validators.required],
      transactionAddress: [{
        value: this.transaction ? this.transaction.transactionAddress : '',
        disabled: true
      }, Validators.required],
      transitAddress: [{
        value: this.transaction ? this.transaction.transitAddress : '',
        disabled: true
      }, Validators.required]
    })
  }

  openAccessWalletDialog() {
    this.dialog.open(AccessToWalletComponent, {
      width: '440px',
      data: {
        coinType: this.coinType,
        address: this.transaction.transactionAddress}
    }).afterClosed().subscribe(result => {
      if (result instanceof WalletDto) {
        // this.isToken
        //   ? this.processTokenTransaction(result, this.transaction)
        //   : this.processWalletTransaction(result, this.transaction);
      }
    })
  }

  private get coinType(): string {
    return this.transaction.pair.fromCoin;
  }

  private get isToken(): boolean {
    return false;
  }
  //
  // private processWalletTransaction(wallet: WalletDto, transaction: TransactionDto) {
  //
  //   switch (this.coinType) {
  //     case 'LAW': {
  //       return this.sendCilTransaction(wallet, transaction);
  //     }
  //     case 'ETH': {
  //       return this.sendEthTransaction(wallet, transaction);
  //     }
  //     default:
  //       throw new Error('Coin type not supported');
  //   }
  // }
  //
  // private sendCilTransaction(wallet: WalletDto, transaction: TransactionDto): Observable<void> {
  //   return this.socialNetworkClient.getWalletListUnspent(wallet.getAddress(), CoinType4.Cil)
  //     .pipe(
  //       mergeMap(unspents => {
  //         const cilTran = CilTransactionHelper.getCilTran(wallet, transaction.transitAddress.substr(2), transaction.amount, unspents);
  //         const request = new TransactionViewModel({
  //           coinType: <TransactionViewModelCoinType>transaction.coinType.toString(),
  //           transaction: cilTran.encode().toString('hex'),
  //         });
  //
  //         return this.socialNetworkClient.sendTransaction(request);
  //       })
  //     );
  // }
  //
  // private sendEthTransaction(wallet: WalletDto, transaction: TransactionDto): Observable<void> {
  //   return this.socialNetworkClient.getTransactionCount(wallet.getAddress())
  //     .pipe(mergeMap(data => {
  //       const transactionRequest: TransactionRequest = {
  //         nonce: data.nonce,
  //         gasLimit: transaction.limit,
  //         gasPrice: utils.bigNumberify(transaction.fee),
  //         to: transaction.addressTo,
  //         value: utils.parseEther(transaction.amount.toString()),
  //         data: '0x'
  //       };
  //       return from(wallet.sign(transactionRequest));
  //     }))
  //     .pipe(mergeMap(data => {
  //       const request = new TransactionViewModel({
  //         coinType: <TransactionViewModelCoinType>transaction.coinType.toString(),
  //         transaction: data as string
  //       });
  //       return this.socialNetworkClient.sendTransaction(request);
  //     }));
  // }

  private processTokenTransaction(wallet: WalletDto, transaction: TransactionDto) {
    // switch (this.coinType) {
    //   case 'LAW': {
    //     return this.sendCilToken(wallet, transaction);
    //   }
    //   case 'ETH': {
    //     return this.sendEthToken(wallet, transaction);
    //   }
    // }
  }
  //
  // private sendCilToken(wallet: WalletDto, transaction: TransactionDto): Observable<void> {
  //   return this.socialNetworkClient.getWalletListUnspent(wallet.getAddress(), CoinType4.Cil)
  //     .pipe(
  //       mergeMap(unspents => {
  //         const arrArguments = [transaction.pair.fromSymbol, transaction.transitAddress.substring(2, transaction.transitAddress.length), transaction.amount];
  //         const cilTran = CilTransactionHelper.getCilSmartContractTran(wallet, transaction.contractAddress, transaction.consiliumIdInCil, 0, transaction.feeInCoin, unspents, 'transfer', arrArguments);
  //         const request = new TransactionViewModel({
  //           coinType: <TransactionViewModelCoinType>transaction.coinType.toString(),
  //           transaction: cilTran.encode().toString('hex'),
  //         });
  //
  //         return this.socialNetworkClient.sendTransaction(request);
  //       })
  //     );
  // }
}
