import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  CreateTransitWalletRequest,
  PairDto,
  PairsClient,
  RateDto,
  TransitClient
} from "../../../api/api.client.generated";
import {FormBuilder, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {PairService} from "../../../services/pair.service";
import {SpinnerService} from "../../../services/spinner.service";
import {CustomValidators} from "../../../helpers/CustomValidators";
import {Subscription} from "rxjs";
import {ErrorFormComponent} from "../../../components/error-form/error-form.component";
import {MatDialog} from "@angular/material/dialog";
import * as MobileDetect from 'mobile-detect';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit, OnDestroy {

  selectTo: boolean = false;
  selectFrom: boolean = false;

  pairs: PairDto[] = [];
  pairsTo: string[] = [];
  // pairTo: string = '';

  pairsFrom: string[] = [];
  pairFrom: string = '';
  currentPair: PairDto;

  mobileDetect = new MobileDetect(window.navigator.userAgent);
  public pair: PairDto;

  public rate: RateDto;

  amountWithoutFee: string = '';
  currentPairSubscriber: Subscription = null;
  getRateSubscriber: Subscription = null;

  form = this.formBuilder.group({
    fromAmount: [0, [Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
    toAmount: [0, [Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
    // fromAddress: ['', [Validators.required, Validators.minLength(42)]],
    toAddress: ['', [Validators.required, Validators.minLength(42)]],
  })

  constructor(protected formBuilder: FormBuilder,
              private transitClient: TransitClient,
              private pairClient: PairsClient,
              private router: Router,
              private pairService: PairService,
              private spinnerService: SpinnerService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {

    this.initPairs();

    this.currentPairSubscriber = this.pairService
      .$currentPair
      .subscribe(pair => {
        if (pair) {
          // console.log('pair: ', pair);
          this.pair = pair;
          this.rate = null;
          this.updateAddressValidators();
          this.spinnerService.$showSpinner.next(true);

          this.getRateSubscriber = this.pairClient.getRate(
            pair.fromCoin,
            pair.fromSymbol,
            pair.toCoin,
            pair.toSymbol
          ).subscribe(rate => {
            this.rate = rate;
            this.updateAmountValidators();
            this.spinnerService.$showSpinner.next(false);
          }, error => {
            this.spinnerService.$showSpinner.next(false);
            this.showError("Error while receive rate data for selected pair from server!");
          })
        } else {
          this.pair = null;
          this.rate = null;
        }
      });



    this.pairService.$holdPair.next(false);
  }

  ngOnDestroy() {
    if (this.currentPairSubscriber) {
      this.currentPairSubscriber.unsubscribe();
    }

    if (this.getRateSubscriber) {
      this.getRateSubscriber.unsubscribe();
    }
  }

  private initPairs() {
    this.spinnerService.$showSpinner.next(true);
    this.pair = null;
    this.rate = null;

    this.pairs = [];
    this.pairsTo = [];
    this.pairsFrom = [];
    this.pairClient.getPairs().subscribe(data => {
      for (const datum of environment.pairs) {
        const findEl = data.find(value =>
          (value.fromCoin === datum.fromCoin && value.fromSymbol === datum.fromSymbol && value.toCoin === datum.toCoin && value.toSymbol === datum.toSymbol)
        )
        if (findEl) {
          // Check if need to change display name
          const fromCoin = (findEl.fromCoin === 'LAW') ? 'Native' : findEl.fromCoin;
          const toCoin = (findEl.toCoin === 'LAW') ? 'Native' : findEl.toCoin;

          for (const displayNames of environment.displaySymbolNames) {
            if (findEl.fromSymbol === displayNames.symbol) {
              findEl.fromDisplayName = displayNames.displayName + '(' + fromCoin + ')';
            }
            if (findEl.toSymbol === displayNames.symbol) {
              findEl.toDisplayName = displayNames.displayName + '(' + toCoin + ')';
            }
          }
          findEl.displayName = findEl.fromDisplayName + ' -> ' + findEl.toDisplayName;
          // findEl.displayName = 'UBPAY(Native)' + ' -> ' + 'UBPAY(Native)';
          this.pairs.push(findEl);

          //Find To in pairsTo
          // const findElTo = this.pairsTo.find(value =>
          //   (value === findEl.toDisplayName)
          // )
          // if (!findElTo) {
          //   this.pairsTo.push(findEl.toDisplayName)
          // }

          //Find From in pairsTo
          const findElFrom = this.pairsFrom.find(value =>
            (value === findEl.fromDisplayName)
          )
          if (!findElFrom) {
            this.pairsFrom.push(findEl.fromDisplayName)
          }

        }
      }
      this.spinnerService.$showSpinner.next(false);

      //Execute ToCoin Change
      // if (this.pairsTo.length > 0) {
      //   //Select 1st pair
      //   this.changeToList(this.pairsTo[0]);
      // }

      //Execute FromCoin Change
      if (this.pairsFrom.length > 0) {
        //Select 1st pair
        this.changeFromList(this.pairsFrom[0]);
      }

    },  error => {
      this.spinnerService.$showSpinner.next(false);
      this.showError("Error while receive exchange list from server!");
    });
  }



  //1 - select TO
  onPairToEventClick(_event: Event) {
    this.pair = null;
    this.rate = null;

    const val = (_event.target as HTMLInputElement).value
    this.changeToList(val);

    this.selectTo = false;
  }

  changeToList(value: string) {
    // this.pairTo = value;
    //
    // this.pairsFrom = [];
    // for (const p of this.pairs) {
    //   if (p.toDisplayName === value) {
    //     this.pairsFrom.push(p.fromDisplayName);
    //   }
    // }
    // this.pairService.$currentPair.next(null);
    //
    // if (this.pairsFrom.length > 0) {
    //   //Select 1st pair
    //   this.changeFromList(this.pairsFrom[0]);
    // }



    const pair = this.pairs.find(x => x.fromDisplayName == this.pairFrom && x.toDisplayName == value);
    this.pairService.$currentPair.next(pair);
  }

  //2 - select From
  onPairFromEventClick(_event: Event) {
    this.pair = null;
    this.rate = null;

    const val = (_event.target as HTMLInputElement).value
    this.changeFromList(val);

    this.selectFrom = false;
  }

  changeFromList(value: string) {

    // const pair = this.pairs.find(x => x.toDisplayName == this.pairTo && x.fromDisplayName == value);
    // this.pairService.$currentPair.next(pair);

    this.pairFrom = value;

    this.pairsTo = [];
    for (const p of this.pairs) {
      if (p.fromDisplayName === value) {
        this.pairsTo.push(p.toDisplayName);
      }
    }
    this.pairService.$currentPair.next(null);

    if (this.pairsTo.length > 0) {
      //Select 1st pair
      this.changeToList(this.pairsTo[0]);
    }


  }

  onFromAmountChange(val: any) {
    if (!this.rate) {
      return;
    }

    const fromAmount = +val.target.value;
    const toAmount = (fromAmount - this.rate.fee) * this.rate.rate;
    if (toAmount >= 0) {
      this.form.controls.toAmount.setValue(toAmount);
    } else {
      this.form.controls.toAmount.setValue(0);
    }
    const fromAmountWithout = fromAmount - this.rate.fee;
    if (fromAmountWithout >= 0) {
      this.amountWithoutFee = fromAmountWithout.toString();
    } else {
      this.amountWithoutFee = '0';
    }


    this.updateAmountValidators();
  }

  onToAmountChange(val: any) {
    if (!this.rate) {
      return;
    }

    const toAmount = +val.target.value;
    this.form.controls.fromAmount.setValue(toAmount / this.rate.rate + this.rate.fee);
    this.amountWithoutFee = (toAmount / this.rate.rate).toString();
    this.updateAmountValidators();
  }

  createSwap() {
    if (!this.form.valid) {
      return;
    }

    if (!this.pair) {
      return;
    }

    this.spinnerService.$showSpinner.next(true);

    this.transitClient
      .createTransit({
        // fromAddress: this.form.controls.fromAddress.value,
        toAddress: this.form.controls.toAddress.value,
        pair: this.pair,
        amount: this.form.controls.fromAmount.value - this.rate.fee
      } as CreateTransitWalletRequest)
      .subscribe(transitWallet => {
        this.spinnerService.$showSpinner.next(false);
        this.router.navigate([`exchange/swap/${transitWallet}`]).then();
      }, error => {
        this.spinnerService.$showSpinner.next(false);
        this.showError("Error while receive transit wallet for swap from server!");
      });
  }

  private updateAddressValidators() {
    // this.form.controls.fromAddress.clearValidators();
    this.form.controls.fromAmount.setValue(0);
    this.form.controls.fromAmount.clearValidators();

    this.form.controls.toAmount.setValue(0);
    this.form.controls.toAmount.clearValidators();

    this.amountWithoutFee = '0';

    this.form.controls.toAddress.clearValidators();
    // this.form.controls.fromAddress
    //   .addValidators([
    //     Validators.required,
    //     Validators.minLength(42),
    //     CustomValidators.swapAddressValidator(this.pair.fromCoin)
    //   ]);
    this.form.controls.toAddress
      .addValidators([
        Validators.required,
        Validators.minLength(42),
        CustomValidators.swapAddressValidator(this.pair.toCoin)
      ]);
    this.form.controls.toAddress.updateValueAndValidity();


    // this.form.controls.fromAddress.updateValueAndValidity();
  }

  private updateAmountValidators() {
    this.form.controls.fromAmount.clearValidators();
    this.form.controls.fromAmount
      .addValidators([
        Validators.required,
        Validators.min(this.rate.minAmountWithFee),
      ]);
    this.form.controls.fromAmount.updateValueAndValidity();
  }

  showError(message: String): void {
    this.dialog.open(ErrorFormComponent, {
      width: '440px',
      data: message
    });
  }

  clickSelectFrom(value: boolean) {
    this.selectFrom = value;
  }

  clickSelectTo(value: boolean) {
    this.selectTo = value;
  }
}
