import {Component, OnInit} from '@angular/core';
import {PairService} from "../../services/pair.service";
import {Router} from "@angular/router";
import * as MobileDetect from 'mobile-detect';
import {CounterService} from "../../services/counter.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  transactionsNum = '';
  mobileDetect = new MobileDetect(window.navigator.userAgent);

  constructor(private pairService: PairService,
              private router: Router,
              private counterService: CounterService) {
    this.transactionsNum = '0';
  }

  ngOnInit(): void {
    this.counterService.updateCounter();

    this.counterService.$counter.subscribe( data => {
      const dataStr = ((data === null) || (data < 0)) ? '-' : String(data);
      this.transactionsNum = dataStr
        .replace(
          /(?!^)(?=(?:\d{3})+$)/g,
          ' '
        );
    })

  }

  goHome() {
    this.pairService.$currentPair.next(null);
    this.router.navigate(['/']).then();
  }


}
