<div *ngIf="!mobileDetect.phone()" class="items pairs">
  <div class="item spacing">
    <div class="order">Create order</div>
<!--    <a *ngIf="navigationUrl.length < 10" class="order-button" (click)="refreshList()" matTooltip="Refresh" matTooltipPosition="below"></a>-->
    <div class="order-button"></div>
  </div>

<!--  <div *ngIf="currentPair">-->
<!--    <span class="pair readonly-wrapper">{{currentPair.displayName}}</span>-->
<!--  </div>-->


<!--  <div *ngIf="pairs.length > 0">-->
<!--    <div *ngFor="let pair of pairsDisplayText" class="item">-->
<!--      <a class="pair" [ngClass]="{'selected': isCurrentPair(pair), 'readonly-wrapper': isHold || isCurrentPair(pair)}" (click)="onPairClick(pair)">{{pair}}</a>-->
<!--    </div>-->
<!--  </div>-->

</div>
<div *ngIf="mobileDetect.phone()">
  <div class="row">
    <div class="col">
      <span class="header-mobile">Send:</span>
    </div>
  </div>
<!--  <div class="row">-->
<!--    <div class="col">-->
<!--      <select class="select-mobile" aria-label="select-mobile" [ngClass]="{'readonly-wrapper': isHold}" (change)="onPairEventClick($event)">-->
<!--        <option selected>Select</option>-->
<!--        <option *ngFor="let pair of pairsDisplayText">{{pair}}</option>-->
<!--      </select>-->
<!--    </div>-->
<!--  </div>-->
</div>

