import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-wallet-qr',
  templateUrl: './wallet-qr.component.html',
  styleUrls: ['./wallet-qr.component.scss']
})
export class WalletQrComponent {
  readonly defaultTooltipText = 'Copy Address Wallet';
  wallet: any;
  tooltipText = this.defaultTooltipText;
  @ViewChild('myTooltip') myTooltip: MatTooltip;

  constructor(
    public dialogRef: MatDialogRef<WalletQrComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string) {
    this.wallet = data;
  }

  copyClick() {
    // this.myTooltip.hide();
    this.tooltipText = 'Address copied';
    this.myTooltip.show();
  }


  closeClick(): void {
    this.dialogRef.close();
  }
}
