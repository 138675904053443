import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-support-dialogs',
  templateUrl: './support-dialogs.component.html',
  styleUrls: ['./support-dialogs.component.scss']
})
export class SupportDialogsComponent {
  @Input() dialogs: any = [];
  @Input() activeDialog: number | null = null;
  @Output() onDialogClick: EventEmitter<any> = new EventEmitter();

  dialogClick(id: number): void {
    this.onDialogClick.emit(id)
  }

  ellipseText(text: string): string {
    return text.length < 45 ? text : text.slice(0, 40).concat('...')
  }
}
