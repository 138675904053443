import { Component, Output, ViewChild, ElementRef, EventEmitter, Input, OnInit, Self } from '@angular/core';
import * as MobileDetect from 'mobile-detect';
import { SupportChatMessageView } from "../../../models/Chat/SupportChatMessageView";
import { SupportChatDialogView } from "../../../models/Chat/supportChatDialogView";
import { FormControl, Validators } from "@angular/forms";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { DestroyToken } from "../../../utils/destroy-token";
import { SupportMsgImageView } from "../models/supportMsgImageView";
import { NgxFileDropHelper } from "../../../utils/ngxFileDropHelper";
import { NgxFileDropEntry } from "ngx-file-drop";

@Component({
  selector: 'app-support-chat',
  templateUrl: './support-chat.component.html',
  styleUrls: ['./support-chat.component.scss'],
  providers: [DestroyToken]
})
export class SupportChatComponent implements OnInit {
  @Input() dialogMessages: SupportChatMessageView[];
  @Input() activeDialog: SupportChatDialogView | null;
  @Input() currentUser: BehaviorSubject<string | null>;

  @Input() loadingProgress: number;
  @Input() messagesSent$: Observable<boolean> = new BehaviorSubject(false);
  @Input() sending$: Observable<boolean> = new BehaviorSubject(false);

  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() sendMessage: EventEmitter<any> = new EventEmitter();
  @Output() createDialog: EventEmitter<any> = new EventEmitter();

  @ViewChild('messages') messagesEl!: ElementRef;

  messageFilesForm: FormControl = new FormControl([]);
  fileExtensions: string[] | string = 'image/*, .jpg, .jpeg, .png, .gif, .webp';
  public readonly supportChatHost = environment.supportChat;

  mobileDetect = new MobileDetect(window.navigator.userAgent);
  messageTextForm: FormControl = new FormControl(null, [Validators.required]);

  userEmail: string | null = null;


  constructor(@Self() private destroy$: DestroyToken, private http: HttpClient) {
  }


  ngOnInit() {
    this.currentUser.subscribe(s => {
      if (s) {
        this.userEmail = s;
      }
    })
    this.messageFilesForm.valueChanges.pipe(this.destroy$.takeUntil())
      .subscribe(a => {
        if (a?.length > 0) {
          this.messageTextForm.clearValidators();
          this.messageTextForm.updateValueAndValidity();
        } else {
          this.messageTextForm.setValidators([Validators.required]);
          this.messageTextForm.updateValueAndValidity();
        }
      })
    this.messagesSent$.pipe(this.destroy$.takeUntil())
      .subscribe(a => {
        if (a) {
          this.messageFilesForm.setValue([]);
          this.messageTextForm.setValue(null);
        }
      })
  }

  closeClick(): void {
    this.onClose.emit()
  }

  onSendMessage() {
    if (this.messageTextForm.invalid)
      return;
    this.sendMessage.emit({text: this.messageTextForm.value, files: this.messageFilesForm.value});
  }
  onFilesChanged(files: NgxFileDropEntry[]) {
    if (files?.length > 0)
      this.messageFilesForm.setValue(NgxFileDropHelper.getFiles(...files));
    else
      this.messageFilesForm.setValue([]);
  }


  async downloadImage(imageView: SupportMsgImageView) {
    const imgUrl = this.supportChatHost + imageView.path;
    const linkElm = document.createElement('a');
    linkElm.setAttribute('href', imgUrl);
    linkElm.setAttribute('download', imageView.originalname);
    linkElm.setAttribute('file', imageView.originalname);
    linkElm.setAttribute('name', imageView.originalname);
    linkElm.click();
  }
}
