import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessToWalletComponent } from './access-to-wallet/access-to-wallet.component';
import { AccessWithKeystoreComponent } from './access-with-keystore/access-with-keystore.component';
import { AccessWithKeyComponent } from './access-with-key/access-with-key.component';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";



@NgModule({
  declarations: [
    AccessToWalletComponent,
    AccessWithKeystoreComponent,
    AccessWithKeyComponent
  ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule
    ],
  exports: [
    AccessToWalletComponent,
    AccessWithKeystoreComponent,
    AccessWithKeyComponent
  ]
})
export class AccessToWalletModule { }
