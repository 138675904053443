import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import * as MobileDetect from 'mobile-detect';
// import AutoPlay = YT.AutoPlay;
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent {

  mobileDetect = new MobileDetect(window.navigator.userAgent);
  playYoutube: boolean = false;
  youtubeLoading: boolean = false;
  playerVars = {autoplay: 1, controls: 1, autohide: 1, fs:0};
  videoId = environment.videoId;

  constructor(private router: Router) {
  }

  playVideo() {
    this.playYoutube = true;
    this.youtubeLoading = true;
  }

  // protected readonly AutoPlay = AutoPlay;

  readyYT(event: YT.PlayerEvent) {
    this.youtubeLoading = false;
    event.target.playVideo();
  }

  goExchange() {
    this.router.navigate(['/exchange']).then();
  }
}
