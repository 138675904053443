import { Component } from '@angular/core';
import * as MobileDetect from 'mobile-detect';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  mobileDetect = new MobileDetect(window.navigator.userAgent);

  constructor() {
  }
}
