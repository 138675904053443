import {AuthService} from "../shared/services/auth.service";
import {Component, OnInit} from "@angular/core";
import {LoaderService} from "../shared/services/loader.service";
import {MatDialog} from "@angular/material/dialog";
import {ErrorFormComponent} from "../shared/components/error-form/error-form.component";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {LiveAnnouncer} from "@angular/cdk/a11y";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {AdminClient, IOptionsData, OptionsData} from "../../api/api.client.generated";

@Component({
  selector: 'app-cdr-page-options',
  templateUrl: './cdr-page-options.component.html',
  styleUrls: ['./cdr-page-options.component.scss']
})
export class CdrPageOptionsComponent implements OnInit {

  link: string = '/' + environment.adminUrl;

  isAdmin = false;

  options: OptionsData | null = null;

  form = new FormGroup({
    bscTokenUbx: new FormControl('',  [Validators.required, Validators.min(0)]),

    ethTokenUbx: new FormControl('',  [Validators.required, Validators.min(0)]),
    ethTokenUbcdr: new FormControl('',  [Validators.required, Validators.min(0)]),
    ethTokenUbxe: new FormControl('',  [Validators.required, Validators.min(0)]),
    ethTokenUbxm: new FormControl('',  [Validators.required, Validators.min(0)]),
    ethTokenUbsn: new FormControl('',  [Validators.required, Validators.min(0)]),
    ethTokenUbxp: new FormControl('',  [Validators.required, Validators.min(0)]),
    ethTokenUbx4t: new FormControl('',  [Validators.required, Validators.min(0)]),
    ethTokenUbxcf: new FormControl('',  [Validators.required, Validators.min(0)]),

    ubixUbx: new FormControl('',  [Validators.required, Validators.min(0)]),
    ubixTokenCdr: new FormControl('',  [Validators.required, Validators.min(0)]),
    ubixTokenLse: new FormControl('',  [Validators.required, Validators.min(0)]),
    ubixTokenMmm: new FormControl('',  [Validators.required, Validators.min(0)]),
    ubixTokenUbsn: new FormControl('',  [Validators.required, Validators.min(0)]),
    ubixTokenWaf: new FormControl('',  [Validators.required, Validators.min(0)]),
    ubixTokenTks: new FormControl('',  [Validators.required, Validators.min(0)]),
    ubixTokenCdf: new FormControl('',  [Validators.required, Validators.min(0)]),

    bnbForBscToken: new FormControl('',  [Validators.required, Validators.min(0)]),
    ethForEthToken: new FormControl('',  [Validators.required, Validators.min(0)]),
    ubxForUbixToken: new FormControl('',  [Validators.required, Validators.min(0)])
  });


  constructor(private router: Router,
              private _liveAnnouncer: LiveAnnouncer,
              private authService: AuthService,
              private loaderService: LoaderService,
              public dialog: MatDialog,
              private _formBuilder: FormBuilder,
              private adminClient: AdminClient) {
  }

  ngOnInit() {
    this.authService.$isAdmin.subscribe(result => {
      this.isAdmin = result;
    });
    // this.refresh();

    this.authService.$isAuth.subscribe(result => {
      if (!result) {
        this.router.navigate([this.link, 'login']);
      }
    });

    //LOAD DATA
    this.loaderService.$showLoader.next(true);

    this.adminClient
      .GetOptions(this.authService.$userId.value)
      .subscribe(result => {

        if (result !== null) {
          this.options = result;

          this.form.controls.bscTokenUbx.setValue(result.bscTokenUbx.toString());

          this.form.controls.ethTokenUbx.setValue(result.ethTokenUbx.toString());
          this.form.controls.ethTokenUbcdr.setValue(result.ethTokenUbcdr.toString());
          this.form.controls.ethTokenUbxe.setValue(result.ethTokenUbxe.toString());
          this.form.controls.ethTokenUbxm.setValue(result.ethTokenUbxm.toString());
          this.form.controls.ethTokenUbsn.setValue(result.ethTokenUbsn.toString());
          this.form.controls.ethTokenUbxp.setValue(result.ethTokenUbxp.toString());
          this.form.controls.ethTokenUbx4t.setValue(result.ethTokenUbx4t.toString());
          this.form.controls.ethTokenUbxcf.setValue(result.ethTokenUbxcf.toString());

          this.form.controls.ubixUbx.setValue(result.ubixUbx.toString());
          this.form.controls.ubixTokenCdr.setValue(result.ubixTokenCdr.toString());
          this.form.controls.ubixTokenLse.setValue(result.ubixTokenLse.toString());
          this.form.controls.ubixTokenMmm.setValue(result.ubixTokenMmm.toString());
          this.form.controls.ubixTokenUbsn.setValue(result.ubixTokenUbsn.toString());
          this.form.controls.ubixTokenWaf.setValue(result.ubixTokenWaf.toString());
          this.form.controls.ubixTokenTks.setValue(result.ubixTokenTks.toString());
          this.form.controls.ubixTokenCdf.setValue(result.ubixTokenCdf.toString());

          this.form.controls.ethForEthToken.setValue(result.ethForEthToken.toString());
          this.form.controls.bnbForBscToken.setValue(result.bnbForBscToken.toString());
          this.form.controls.ubxForUbixToken.setValue(result.ubxForUbixToken.toString());


        } else {
          this.options = null;
        }

        this.loaderService.$showLoader.next(false);

      }, error => {
        this.loaderService.$showLoader.next(false);
        this.options = null;

        this.showError("Error while make request to server OPTIONS!");
      });


  }



  showError(message: String, isError: boolean = true): void {
    this.dialog.open(ErrorFormComponent, {
      width: '440px',
      data: {'message': message, 'isError': isError}
    });
  }

  makeRequest() {

    const data: IOptionsData =
    {
      bscTokenUbx: Number(this.form.controls.bscTokenUbx.value),

      ethTokenUbx: Number(this.form.controls.ethTokenUbx.value),
      ethTokenUbcdr: Number(this.form.controls.ethTokenUbcdr.value),
      ethTokenUbxe: Number(this.form.controls.ethTokenUbxe.value),
      ethTokenUbxm: Number(this.form.controls.ethTokenUbxm.value),
      ethTokenUbsn: Number(this.form.controls.ethTokenUbsn.value),
      ethTokenUbxp: Number(this.form.controls.ethTokenUbxp.value),
      ethTokenUbx4t: Number(this.form.controls.ethTokenUbx4t.value),
      ethTokenUbxcf: Number(this.form.controls.ethTokenUbxcf.value),

      ubixUbx: Number(this.form.controls.ubixUbx.value),
      ubixTokenCdr: Number(this.form.controls.ubixTokenCdr.value),
      ubixTokenLse: Number(this.form.controls.ubixTokenLse.value),
      ubixTokenMmm: Number(this.form.controls.ubixTokenMmm.value),
      ubixTokenUbsn: Number(this.form.controls.ubixTokenUbsn.value),
      ubixTokenWaf: Number(this.form.controls.ubixTokenWaf.value),
      ubixTokenTks: Number(this.form.controls.ubixTokenTks.value),
      ubixTokenCdf: Number(this.form.controls.ubixTokenCdf.value),

      bnbForBscToken: Number(this.form.controls.bnbForBscToken.value),
      ethForEthToken: Number(this.form.controls.ethForEthToken.value),
      ubxForUbixToken: Number(this.form.controls.ubxForUbixToken.value)
    };

    this.loaderService.$showLoader.next(true);

    this.adminClient
      .UpdateOptions(data, this.authService.$userId.value)
      .subscribe(result => {

        this.loaderService.$showLoader.next(false);

        if (result !== null && result.isOk === true) {

          this.showError("Update SUCCESS!", false);

        } else {
          this.showError("Update ERROR!", true);
        }



      }, error => {
        this.loaderService.$showLoader.next(false);

        this.showError("Error while make request to server UPDATE OPTIONS!");
      });
  }
}
