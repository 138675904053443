import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {PairDto} from "../api/api.client.generated";

@Injectable({
  providedIn: 'root'
})
export class PairService {

  public $currentPair: BehaviorSubject<PairDto> = new BehaviorSubject<PairDto>(null);
  public $holdPair: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() { }
}
