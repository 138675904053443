import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {PairService} from "../../../services/pair.service";
import {WalletQrComponent} from "../wallet-qr/wallet-qr.component";
import {
  CreateTransactionRequest,
  TransactionClient,
  TransitClient,
  TransitWalletDto
} from "../../../api/api.client.generated";
import {SpinnerService} from "../../../services/spinner.service";
import {MatDialog} from "@angular/material/dialog";
import {MatTooltip} from "@angular/material/tooltip";
import {ErrorFormComponent} from "../../../components/error-form/error-form.component";
import {Subscription, timer} from 'rxjs';
import {CounterService} from "../../../services/counter.service";
import * as MobileDetect from 'mobile-detect';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-swap',
  templateUrl: './swap.component.html',
  styleUrls: ['./swap.component.scss']
})
export class SwapComponent implements OnInit, OnDestroy {

  mobileDetect = new MobileDetect(window.navigator.userAgent);
  sendForm: FormGroup;
  walletDto: TransitWalletDto;
  swapStatus = 'Unknown';
  statusTimer = 0;
  timerSubscribe: Subscription = null;
  remainSeconds = 0;
  timeResult = '24:00:00';
  showInterface = false;
  showAddress = false;

  readonly defaultTooltipText = 'Copy Address Wallet';
  tooltipText = this.defaultTooltipText;
  @ViewChild('myTooltip') myTooltip: MatTooltip;

  constructor(protected formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private transactionClient: TransactionClient,
              private transitClient: TransitClient,
              private router: Router,
              private spinnerService: SpinnerService,
              private dialog: MatDialog,
              private pairService: PairService,
              private counterService: CounterService) {
  }

  ngOnInit(): void {
    this.counterService.updateCounter();

    this.route.paramMap.subscribe((params: ParamMap) =>  {
      const transitWallet = params.get('transitWallet');
      this.spinnerService.$showSpinner.next(true);

      this.timerSubscribe = timer(0, 1000).subscribe(val => {
        //update swap status
        if (this.statusTimer === 0) {
          this.transitClient
            .GetSwapStatus(transitWallet)
            .subscribe(data => {
              if (data) {
                this.swapStatus = data.status ?? 'Unknown';
                this.remainSeconds = data.remainSeconds ?? 0;

                if (this.remainSeconds < 0) {
                  this.timeResult = 'Unknown';
                  try {
                    this.timerSubscribe.unsubscribe();
                  } catch {}
                } else if (this.remainSeconds < 86400) {
                  const seconds = 86400 - this.remainSeconds;

                  this.timeResult = (seconds < 86400) ? new Date(seconds * 1000).toISOString().slice(11, 19) : '24:00:00';
                  this.showInterface = true;
                  this.showAddress = true;
                } else {
                  //time expires
                  this.timeResult = 'EXPIRED';
                  this.showInterface = true;
                  this.showAddress = false;

                  try {
                    this.timerSubscribe.unsubscribe();
                  } catch {}
                }
              } else {
                this.swapStatus = 'Unknown';
                this.timeResult = 'Unknown';
                this.showInterface = true;
                this.showAddress = true;
              }
            }, error => {
              this.swapStatus = 'Unknown';
              this.timeResult = 'Unknown';
              this.showInterface = true;
              this.showAddress = true;
            });
          this.statusTimer = 30;
        } else {
          this.statusTimer--;
          //update timer
          if ((this.remainSeconds < 86399) && (this.remainSeconds >= 0)) {
            this.remainSeconds++;
            const seconds = 86400 - this.remainSeconds;
            this.timeResult = new Date(seconds * 1000).toISOString().slice(11, 19);
          }
        }
      });

      this.transitClient
        .getRateByTransitWallet(transitWallet)
        .subscribe(data => {

          // Check if need to change display name
          const fromCoin = (data.pair.fromCoin === 'LAW') ? 'Native' : data.pair.fromCoin;
          const toCoin = (data.pair.toCoin === 'LAW') ? 'Native' : data.pair.toCoin;

          for (const displayNames of environment.displaySymbolNames) {
            if (data.pair.fromSymbol === displayNames.symbol) {
              data.pair.fromDisplayName = displayNames.displayName + '(' + fromCoin + ')';
            }
            if (data.pair.toSymbol === displayNames.symbol) {
              data.pair.toDisplayName = displayNames.displayName + '(' + toCoin + ')';
            }
          }
          data.pair.displayName = data.pair.fromDisplayName + ' -> ' + data.pair.toDisplayName;
          this.walletDto = data;

          this.pairService.$currentPair.next(data.pair);
          this.pairService.$holdPair.next(true);
          this.initForm();
          this.spinnerService.$showSpinner.next(false);
        }, error => {
          this.spinnerService.$showSpinner.next(false);
          this.showError("Error while receive exchange data for transit wallet from server!");
        });

    })
  }

  ngOnDestroy(): void {
    try {
      this.timerSubscribe.unsubscribe();
    }
    catch {
    }

  }

  initForm() {
    this.sendForm = this.formBuilder.group({
      amount: [this.walletDto ? this.walletDto.rate.minAmountWithFee : 0, Validators.required],
      transactionAddress: ['', Validators.required],
      transitAddress: [{
        value: this.walletDto ? this.walletDto.address : '',
        disabled: true
      }, Validators.required]
    })
  }

  createTransaction() {
    if (!this.sendForm.valid) {
      return;
    }

    this.spinnerService.$showSpinner.next(true);

    this.transactionClient.create({
      amount: this.sendForm.value.amount,
      address: this.sendForm.value.transactionAddress,
      transitAddress: this.walletDto.address
    } as CreateTransactionRequest).subscribe(data => {
      this.spinnerService.$showSpinner.next(false);
      this.router.navigate([`send-confirm/${data.transitAddress}`]).then()
    })
  }

  showQR(): void {
    const dialogRef = this.dialog.open(WalletQrComponent, {
      width: '440px',
      panelClass: 'copyQR',
      data: {hashAddress: this.walletDto.address, name: 'Swap address'}
    });
  }

  copyClick() {
    this.tooltipText = 'Address copied';
    this.myTooltip.show();
    this.myTooltip.hide(2000);
  }

  showTooltip() {
    this.tooltipText = this.defaultTooltipText;
    this.myTooltip.show();
  }

  hideTooltip() {
    this.myTooltip.hide();

  }


  closeSwap() {
    this.router.navigate([`/exchange`]).then();
  }

  showError(message: String): void {
    this.dialog.open(ErrorFormComponent, {
      width: '440px',
      data: message
    });
  }


}
