import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {environment} from "../environments/environment";
import {HttpClientModule} from "@angular/common/http";
import {API_BASE_URL, TransactionClient, TransitClient, PairsClient} from "./api/api.client.generated";
import { HeaderComponent } from './components/header/header.component';
import {SwapModule} from "./modules/swap/swap.module";
import {RouterOutlet} from "@angular/router";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {AccessToWalletModule} from "./modules/access-to-wallet/access-to-wallet.module";
import { SpinnerComponent } from './components/spinner/spinner.component';
import {FooterComponent} from "./components/footer/footer.component";
import {ErrorFormComponent} from "./components/error-form/error-form.component";
import {ClipboardModule} from "ngx-clipboard";
import {QRCodeModule} from "angularx-qrcode";
import {MatTooltipModule} from "@angular/material/tooltip";
import {YouTubePlayerModule} from "@angular/youtube-player";
import {AdminModule} from "./admin/admin.module";
import { SupportModule } from './modules/support/support.module';
import {IdentityClient} from "./api/IdentityClient";
import {SupportChatApiClient} from "./api/supportChatApiClient";
import {AppHeadersServiceProvider} from "./api/appHeaders.service.provider";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SpinnerComponent,
    ErrorFormComponent
  ],
    imports: [
      SwapModule,
      AdminModule,
      RouterOutlet,
      BrowserModule,
      AppRoutingModule,
      HttpClientModule,
      MatProgressSpinnerModule,
      AccessToWalletModule,
      ClipboardModule,
      QRCodeModule,
      MatTooltipModule,
      YouTubePlayerModule,
      SupportModule
    ],
  providers: [
    {provide: API_BASE_URL, useValue: environment.apiUrl},
    TransactionClient,
    TransitClient,
    PairsClient,
    IdentityClient,
    SupportChatApiClient,
    AppHeadersServiceProvider,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
