<div *ngIf="!mobileDetect.phone()" class="my-create">
  <div class="row">
    <div class="col">
      <div class="background-value"></div>
      <div class="details">
    <!--    <div *ngIf="!pair" class="select-text">Please, choose pair from exchange list</div>-->
    <!--    <div *ngIf="pair && rate">-->
        <div>
          <form [formGroup]="form" (submit)="createSwap()">
            <div class="dialogs">
              <div class="receive">
                <div class="text">Send:</div>
                <div class="box">

                  <select class="select-pair" [ngClass]="{'select-pair-open': selectFrom}" (change)="onPairFromEventClick($event)" (focus)="clickSelectFrom(true)" (focusout)="clickSelectFrom(false)" (blur)="clickSelectFrom(false)">
                    <option *ngFor="let pair of pairsFrom">{{pair}}</option>
                  </select>

                  <div class="caption">Amount:</div>
                  <mat-form-field class="example-full-width">
                    <input class="send-input" type="number" matInput formControlName="fromAmount" (input)="onFromAmountChange($event)">
                    <mat-icon *ngIf="form.controls.fromAmount.invalid" class="red-icon" matSuffix>error</mat-icon>
                  </mat-form-field>
                  <mat-error *ngIf="form.controls.fromAmount.hasError('required')">Amount is required
                  </mat-error>
                  <mat-error *ngIf="!form.controls.fromAmount.hasError('required') &&
                                form.controls.fromAmount.hasError('min') && rate && pair">Minimum amount with fee is {{this.rate.minAmountWithFee}}
                  </mat-error>
                  <div *ngIf="rate && pair">
                    <div class="swap-data">Min amount: {{this.rate.minAmount}} {{this.pair.fromDisplayName}}</div>
                    <div class="swap-data">Fee: {{this.rate.fee}} {{this.pair.fromDisplayName}}</div>
                    <div class="swap-data space">Min amount with fee: {{this.rate.minAmountWithFee}} {{this.pair.fromDisplayName}}</div>
                    <!--              <div class="caption">{{pair.fromDisplayName}} amount without fee:</div>-->
                    <!--              <input class="send-commission-input" [value]="amountWithoutFee" disabled>-->
                  </div>
                </div>
              </div>

              <div class="arrows">
                <div class="polygon-1"></div>
                <div class="polygon-2"></div>
              </div>

              <div class="send">
                <div class="text">Receive:</div>
                <div class="box">


                  <select class="select-pair" [ngClass]="{'select-pair-open': selectTo}" (change)="onPairToEventClick($event)" (focus)="clickSelectTo(true)" (focusout)="clickSelectTo(false)" (blur)="clickSelectTo(false)">
                    <option *ngFor="let pair of pairsTo">{{pair}}</option>
                  </select>

                  <div class="caption">Amount:</div>
                  <mat-form-field class="example-full-width">
                    <input class="receive-input" type="number" matInput formControlName="toAmount" (input)="onToAmountChange($event)">
                    <mat-icon *ngIf="form.controls.toAmount.invalid" class="red-icon" matSuffix>error</mat-icon>
                  </mat-form-field>

                  <mat-error *ngIf="form.controls.toAmount.hasError('required')">Amount is required
                  </mat-error>
                  <mat-error *ngIf="!form.controls.toAmount.hasError('required') &&
                                form.controls.toAmount.hasError('min')">Invalid amount
                  </mat-error>
                  <div *ngIf="pair && rate">
                    <div class="swap-data">Rate: 1 {{this.pair.toDisplayName}} = {{this.rate.rate}} {{this.pair.fromDisplayName}}</div>
                    <input class="receive-address-input" placeholder="Wallet to receive" type="text" matInput formControlName="toAddress">
                    <mat-icon *ngIf="form.controls.toAddress.invalid" class="red-icon icon-address-error" matSuffix>error</mat-icon>
                    <mat-error *ngIf="form.controls.toAddress.hasError('required')">Wallet to receive address is required
                    </mat-error>
                    <mat-error *ngIf="!form.controls.toAddress.hasError('required') &&
                                  (form.controls.toAddress.hasError('minlength') ||
                                  form.controls.toAddress.hasError('swapAddress'))">Invalid address
                    </mat-error>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-inside">
              <button submit class="make-order" [disabled]="form.invalid">
                <span class="button-text" matTooltip="Fields are not filled!" matTooltipPosition="below" [matTooltipDisabled]="!form.invalid" >Create order</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="mobileDetect.phone()">
<!--  <div class="row">-->
<!--    <div class="col">-->
<!--      <div *ngIf="!pair" class="select-text-mobile">Please, choose pair from exchange list</div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div *ngIf="pair && rate">-->
  <div>
    <form [formGroup]="form" (submit)="createSwap()">

      <div class="row">
        <div class="col">
          <div class="text-mobile">Send:</div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <select class="select-pair select-pair-mobile" [ngClass]="{'select-pair-open': selectFrom}" (change)="onPairFromEventClick($event)" (focus)="clickSelectFrom(true)" (focusout)="clickSelectFrom(false)" (blur)="clickSelectFrom(false)">
            <option *ngFor="let pair of pairsFrom">{{pair}}</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="caption">Amount:</div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <input class="send-input send-input-mobile" type="number" matInput formControlName="fromAmount" (input)="onFromAmountChange($event)">
          <mat-error *ngIf="form.controls.fromAmount.hasError('required')">Amount is required
          </mat-error>
          <mat-error *ngIf="!form.controls.fromAmount.hasError('required') &&
                        form.controls.fromAmount.hasError('min') && rate && pair">Minimum amount with fee is {{this.rate.minAmountWithFee}}
          </mat-error>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div *ngIf="rate && pair">
            <div class="swap-data">Min amount: {{this.rate.minAmount}} {{this.pair.fromDisplayName}}</div>
            <div class="swap-data">Fee: {{this.rate.fee}} {{this.pair.fromDisplayName}}</div>
            <div class="swap-data space">Min amount with fee: {{this.rate.minAmountWithFee}} {{this.pair.fromDisplayName}}</div>
            <!--            <div class="caption">{{pair.fromDisplayName}} amount without fee:</div>-->
            <!--            <input class="send-commission-input send-commission-input-mobile" [value]="amountWithoutFee" disabled>-->
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="text-mobile">Receive:</div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <select class="select-pair select-pair-mobile" [ngClass]="{'select-pair-open': selectTo}" (change)="onPairToEventClick($event)" (focus)="clickSelectTo(true)" (focusout)="clickSelectTo(false)" (blur)="clickSelectTo(false)">
            <option *ngFor="let pair of pairsTo">{{pair}}</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="caption">Amount:</div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <input class="receive-input receive-input-mobile" type="number" matInput formControlName="toAmount" (input)="onToAmountChange($event)">
          <mat-error *ngIf="form.controls.toAmount.hasError('required')">Amount is required
          </mat-error>
          <mat-error *ngIf="!form.controls.toAmount.hasError('required') &&
                        form.controls.toAmount.hasError('min')">Invalid amount
          </mat-error>
        </div>
      </div>
      <div class="row">
        <div *ngIf="rate && pair" class="col">
          <div class="swap-data">Rate: 1 {{this.pair.toDisplayName}} = {{this.rate.rate}} {{this.pair.fromDisplayName}}</div>
        </div>
      </div>
      <div class="row">
        <div *ngIf="rate && pair" class="col">
          <input class="receive-address-input receive-address-input-mobile" placeholder="Wallet to receive" type="text" matInput formControlName="toAddress">
          <mat-error *ngIf="form.controls.toAddress.hasError('required')">Wallet to receive address is required
          </mat-error>
          <mat-error *ngIf="!form.controls.toAddress.hasError('required') &&
                        (form.controls.toAddress.hasError('minlength') ||
                        form.controls.toAddress.hasError('swapAddress'))">Invalid address
          </mat-error>
        </div>
      </div>


      <div class="row">
        <div class="col">
          <button submit class="make-order make-order-mobile" [disabled]="form.invalid">
            <span class="button-text">Create order</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
