import {NgModule} from '@angular/core';
import {PairsComponent} from "./pairs/pairs.component";
import {CreateSwapComponent} from "./create-swap/create-swap.component";
import {SendComponent} from "./send/send.component";
import {LayoutComponent} from "./layout/layout.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {RouterOutlet} from "@angular/router";
import {MatListModule} from "@angular/material/list";
import {SwapRoutingModule} from "./swap-routing.module";
import {CommonModule} from "@angular/common";
import {MatInputModule} from "@angular/material/input";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatButtonModule} from "@angular/material/button";
import {SendConfirmComponent} from './send-confirm/send-confirm.component';
import {MatDialogModule} from "@angular/material/dialog";
import {WalletQrComponent} from './wallet-qr/wallet-qr.component';
import {QRCodeModule} from 'angularx-qrcode';
import {ClipboardModule} from "ngx-clipboard";
import {MatTooltipModule} from "@angular/material/tooltip";
import {CreateComponent} from './create/create.component';
import {SwapComponent} from './swap/swap.component';
import {LandingComponent} from "./landing/landing.component";
import { YouTubePlayerModule } from "@angular/youtube-player";
import { FlexLayoutModule, FlexModule } from "@angular/flex-layout";
import {MatIconModule} from "@angular/material/icon";
import {MatSelectModule} from "@angular/material/select";

@NgModule({
  declarations: [
    LandingComponent,
    PairsComponent,
    CreateSwapComponent,
    SendComponent,
    LayoutComponent,
    SendConfirmComponent,
    WalletQrComponent,
    CreateComponent,
    SwapComponent
  ],
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        RouterOutlet,
        MatListModule,
        SwapRoutingModule,
        CommonModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatDialogModule,
        QRCodeModule,
        ClipboardModule,
        MatTooltipModule,
        FlexModule,
        FlexLayoutModule,
        YouTubePlayerModule,
        MatIconModule,
        MatSelectModule
    ]
})
export class SwapModule { }
