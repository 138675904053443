<div class="qr-dialog">
  <button class="close" (click)="closeClick()">X</button>
  <h2> {{wallet.name}}</h2>
  <qrcode [style]="{display: 'flex', 'justify-content': 'center'}" [qrdata]="wallet.hashAddress" [width]="159"></qrcode>
  <!--suppress AngularInvalidTemplateReferenceVariable -->
  <div class="wallet-address"
       #myTooltip="matTooltip"
       [matTooltip]="tooltipText"
       matTooltipPosition="above"
       ngxClipboard
       [cbContent]="wallet.hashAddress"
       (click)="copyClick()"
       (mouseleave) ="tooltipText = defaultTooltipText"
  >{{wallet.hashAddress}}</div>
</div>
