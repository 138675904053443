import { WalletDto } from './WalletDto';
import { Wallet } from 'ethers';
import { TransactionRequest } from 'ethers/providers';

export class EthWalletDto extends WalletDto {
  private wallet: Wallet;

  constructor(privateKey: string = null, keyFile: string = null) {
    super(privateKey, keyFile);
    if (privateKey) {
      this.wallet = new Wallet(privateKey);
    }
    else if (keyFile) {
      this.wallet = <Wallet>{};
    }
    else {
      this.wallet = Wallet.createRandom();
    }

    this.address = this.wallet.address;
    this.privateKey = this.wallet.privateKey;
  }

  async encrypt(password: string): Promise<void> {
    this.keyFile = await this.wallet.encrypt(password);
  }

  async decrypt(password: string): Promise<void> {
    this.wallet = await Wallet.fromEncryptedJson(this.keyFile, password);
    this.address = this.wallet.address;
    this.privateKey = this.wallet.privateKey;
  }

  sign(tran: TransactionRequest): Promise<string> {
    return this.wallet.sign(tran);
  }

  getAddress(): string {
    return this.wallet.address;
  }

  getKeyFileName(): string {
    return 'ETH-KeyStore-' + new Date().toISOString();
  }
}
