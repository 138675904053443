import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BaseAccessToWallet} from "../BaseAccessToWallet";

@Component({
  selector: 'app-access-to-wallet',
  templateUrl: './access-to-wallet.component.html',
  styleUrls: ['./access-to-wallet.component.scss']
})
export class AccessToWalletComponent extends BaseAccessToWallet {

  @Output() success = new EventEmitter<boolean>();

  constructor(
    private df: MatDialogRef<AccessToWalletComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    super(df);
    this.data = data;
  }

  protected finishAction(): void {
    this.df.close(this.wallet);
  }
}
