import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export class CustomValidators {

  public static swapAddressValidator (coin: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || !coin) {
        return null;
      }

      if (coin === "LAW" && !control.value.startsWith('Ux')) {
        return {swapAddress: true};
      }

      if (coin === "ETH" && !control.value.startsWith('0x')) {
        return {swapAddress: true};
      }

      if (coin === "BSC" && !control.value.startsWith('0x')) {
        return {swapAddress: true};
      }

      if (coin === "OP" && !control.value.startsWith('0x')) {
        return {swapAddress: true};
      }

      return null;
    };
  }
}
