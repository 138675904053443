import { ApiClientBase } from "./apiClientBase.service";
import { HttpClient } from "@angular/common/http";
import { AppHeadersService } from "./appHeaders.service";
import { Injectable } from "@angular/core";

@Injectable()
export class SupportChatApiClient extends ApiClientBase {
  constructor(http: HttpClient, headers: AppHeadersService) {
    super(http, headers);
  }

  override authorization: string;

  updateAuth(s: string) {
    this.authorization = s;
  }
}
