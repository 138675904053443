<div fxFlex="auto" class="file-drop {{type}}" fxLayout="{{type!='default'? 'row-reverse':'row'}}"
     fxLayoutAlign="center center">
  <ngx-file-drop class="{{type}}" fxFlex [multiple]="multiple" (onFileDrop)="dropped($event)"
                 (onFileLeave)="eventTouched.emit($event)"
                 (click)="eventTouched.emit($event)"
                 contentClassName="dd-container" dropZoneClassName="dd-zone"
                 fxLayout="column"
                 [accept]="acceptedExtensions"
                 *ngIf="!readonly && (multiple || !multiple && !(files?.length>0))"
  >
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      <div class="dropzone {{type}}" [ngStyle]="{'height':height}">
        <div class="dropzone-label {{color}}" [ngStyle]="{'line-height':lineHeight}"
             fxLayoutAlign="center center" fxLayout="row" (click)="openFileSelector()">
          <span *ngIf="description">{{ description }}</span>
          <div fxLayoutAlign="center center">
            <mat-icon matBadge="{{files.length??''}}" matBadgeSize="small"
                      [matBadgeOverlap]="true"
                      matBadgeColor="warn"
                      [matBadgeDisabled]="true"
                      fxLayout="row"
                      fxLayoutAlign="center center">{{ attachFileIcon }}
            </mat-icon>
          </div>
        </div>
      </div>
    </ng-template>
  </ngx-file-drop>
  <ng-container *ngIf="files?.length>0" [ngTemplateOutlet]="filesViewTemplate">
  </ng-container>
</div>
<ng-template #filesViewTemplate>
  <div *ngIf="files?.length>0" fxLayout="column" class="files {{type}}">
    <div *ngFor="let file of files" fxLayout="row" fxLayoutAlign="center center"
         class="file-loaded {{type}}">
      <button mat-button color="accent" class="file-button {{type}}" fxLayoutAlign="start center">
        <mat-icon matPrefix>
          {{ file|fileIconName }}
        </mat-icon>
        <div class="image-name" [matTooltip]="file.relativePath">
          {{ file.relativePath }}
        </div>
      </button>
      <button mat-icon-button color="{{type != 'default' ? 'warn' : 'primary'}}" fxLayoutAlign="center center"
              (click)="deleteItem(file)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</ng-template>
<ng-content></ng-content>

