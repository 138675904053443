<div *ngIf="mobileDetect.phone()" class="ellipse-layout-mobile"></div>
<div *ngIf="mobileDetect.phone()" class="flex flex-mobile">
  <router-outlet></router-outlet>
</div>
<div *ngIf="!mobileDetect.phone()">
  <router-outlet></router-outlet>
</div>




