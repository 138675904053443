import {CilWalletDto} from './CilWalletDto';
import {EthWalletDto} from './EthWalletDto';
import {WalletDto} from './WalletDto';

export default {
  get(type: string, privateKey: string = null, keyFile: string = null): WalletDto {
    if (type === 'LAW') {
      return new CilWalletDto(privateKey, keyFile);
    } else {
      return new EthWalletDto(privateKey, keyFile);
    }
  }
};
