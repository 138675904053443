import { MonoTypeOperatorFunction, ReplaySubject } from "rxjs";
import { Injectable, OnDestroy } from "@angular/core";
import { takeUntil as takeUntilDestroy } from "rxjs/operators";

@Injectable()
export class DestroyToken extends ReplaySubject<void> implements OnDestroy {
    constructor() {
        super();
    }

    public notify(): void {
        this.next();
        this.complete();
    }

    ngOnDestroy(): void {
        this.notify();
    }

    takeUntil<T>(): MonoTypeOperatorFunction<T> {
        return takeUntilDestroy<T>(this);
    }
}
