import {AuthService} from "../shared/services/auth.service";
import {Component, OnInit} from "@angular/core";
import {LoaderService} from "../shared/services/loader.service";
import {MatDialog} from "@angular/material/dialog";
import {ErrorFormComponent} from "../shared/components/error-form/error-form.component";
import {FormBuilder} from "@angular/forms";
import {LiveAnnouncer} from "@angular/cdk/a11y";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {AdminClient, OptionsData} from "../../api/api.client.generated";

@Component({
  selector: 'app-cdr-page',
  templateUrl: './cdr-page.component.html',
  styleUrls: ['./cdr-page.component.scss']
})
export class CdrPageComponent implements OnInit {

  link: string = '/' + environment.adminUrl;

  balanceBep20Ubx: number | undefined | null = null;
  balanceBnbForUbx: number | undefined | null = null;

  balanceErc20Ubx: number | undefined | null = null;
  balanceErc20Ubcdr: number | undefined | null = null;
  balanceErc20Ubxe: number | undefined | null = null;
  balanceErc20Ubxm: number | undefined | null = null;
  balanceErc20Ubsn: number | undefined | null = null;

  //NEW
  balanceErc20Ubxp: number | undefined | null = null;
  balanceErc20Ubx4t: number | undefined | null = null;
  balanceErc20Ubxcf: number | undefined | null = null;

  balanceUbixUbx: number | undefined | null = null;
  balanceUbixCdr: number | undefined | null = null;
  balanceUbixLse: number | undefined | null = null;
  balanceUbixMmm: number | undefined | null = null;
  balanceUbixUbsn: number | undefined | null = null;

  //NEW
  balanceUbixWaf: number | undefined | null = null; //UBWF
  balanceUbixTks: number | undefined | null = null; //UBX4T
  balanceUbixCdf: number | undefined | null = null; //UBPAY

  //WALLET BALANCES FOR TOKENS
  balanceEthForUbx: number | undefined | null = null;
  balanceEthForUbcdr: number | undefined | null = null;
  balanceEthForUbxe: number | undefined | null = null;
  balanceEthForUbxm: number | undefined | null = null;
  balanceEthForUbsn: number | undefined | null = null;

  //NEW
  balanceEthForUbxp: number | undefined | null = null;
  balanceEthForUbx4t: number | undefined | null = null;
  balanceEthForUbxcf: number | undefined | null = null;

  balanceUbxForCdr: number | undefined | null = null;
  balanceUbxForLse: number | undefined | null = null;
  balanceUbxForMmm: number | undefined | null = null;
  balanceUbxForUbsn: number | undefined | null = null;

  //NEW
  balanceUbxForWaf: number | undefined | null = null; //UBWF
  balanceUbxForTks: number | undefined | null = null; //UBX4T
  balanceUbxForCdf: number | undefined | null = null; //UBPAY

  isAdmin = false;

  options: OptionsData | null = null;

  constructor(private router: Router,
              private _liveAnnouncer: LiveAnnouncer,
              private authService: AuthService,
              private loaderService: LoaderService,
              // protected socialNetworkClient: SocialNetworkClient,
              public dialog: MatDialog,
              private _formBuilder: FormBuilder,
              private adminClient: AdminClient) {
  }

  ngOnInit() {
    this.authService.$isAdmin.subscribe(result => {
      this.isAdmin = result;
    });
    // this.refresh();

    this.authService.$isAuth.subscribe(result => {
      if (!result) {
        this.router.navigate([this.link, 'login']);
      }
    });

    // this.makeRequest();
  }


  // REQUEST -----------------------------------------------------------------------
  makeRequest() {


      this.loaderService.$showLoader.next(true);

      if (this.authService.$isAuth.value) {

        this.options = null;

        this.balanceBep20Ubx = null;
        this.balanceBnbForUbx = null;

        this.balanceEthForUbx = null;
        this.balanceEthForUbcdr = null;
        this.balanceEthForUbxe = null;
        this.balanceEthForUbxm = null;
        this.balanceEthForUbsn = null;
        this.balanceEthForUbxp = null;
        this.balanceEthForUbx4t = null;
        this.balanceEthForUbxcf = null;

        this.balanceUbxForCdr = null;
        this.balanceUbxForLse = null;
        this.balanceUbxForMmm = null;
        this.balanceUbxForUbsn = null;
        this.balanceUbxForWaf = null;
        this.balanceUbxForTks = null;
        this.balanceUbxForCdf = null;

        this.balanceErc20Ubx = null;
        this.balanceErc20Ubcdr = null;
        this.balanceErc20Ubxe = null;
        this.balanceErc20Ubxm = null;
        this.balanceErc20Ubsn = null;
        this.balanceErc20Ubxp = null;
        this.balanceErc20Ubx4t = null;
        this.balanceErc20Ubxcf = null;

        this.balanceUbixUbx = null;
        this.balanceUbixCdr = null;
        this.balanceUbixLse = null;
        this.balanceUbixMmm = null;
        this.balanceUbixUbsn = null;
        this.balanceUbixWaf = null;
        this.balanceUbixTks = null;
        this.balanceUbixCdf = null;



        this.adminClient
          .GetBscBalance('0xbc024554f814C2F608811aBeb5F7D5166407Cfa8', this.authService.$userId.value)
          .subscribe(result => {

            if (result !== null) {
              this.balanceBnbForUbx = result.balance;

            } else {
              this.balanceBnbForUbx = null;
            }

            this.adminClient
              .GetBscTokenBalance('UBX', this.authService.$userId.value)
              .subscribe(result => {

                if (result !== null) {
                  this.balanceBep20Ubx = result.balance;
                } else {
                  this.balanceBep20Ubx = null;
                }
              }, error => {
                this.balanceBep20Ubx = null;

                this.showError("Error while make request to server BSC!");
              });

          }, error => {
            this.balanceBnbForUbx = null;
            this.balanceBep20Ubx = null;

            this.showError("Error while make request to server BSC!");
          });


        this.adminClient
          .GetOptions(this.authService.$userId.value)
          .subscribe(result => {

            if (result !== null) {
              this.options = result;
            } else {
              this.options = null;
            }

          }, error => {
            this.options = null;

            this.showError("Error while make request to server OPTIONS!");
          });

        this.adminClient
          .GetEthBalance('0x6a8fae70f11f332075dca3ebbb0d1469a72ebf4b', this.authService.$userId.value)
          .subscribe(result => {

            if (result !== null) {
              this.balanceEthForUbx = result.balance;
            } else {
              this.balanceEthForUbx = null;
            }

            this.adminClient
              .GetEthBalance('0x4da600904bfcdf48de483ebdaca0e4b640c6495d', this.authService.$userId.value)
              .subscribe(result => {

                if (result !== null) {
                  this.balanceEthForUbcdr = result.balance;
                  this.balanceEthForUbxe = result.balance;
                  this.balanceEthForUbxm = result.balance;

                } else {
                  this.balanceEthForUbcdr = null;
                  this.balanceEthForUbxe = null;
                  this.balanceEthForUbxm = null;
                }

                this.adminClient
                  .GetEthBalance('0xAB9123c66330D15432B99C4A47bDa3038BB96225', this.authService.$userId.value)
                  .subscribe(result => {

                    if (result !== null) {
                      this.balanceEthForUbxp = result.balance;
                      this.balanceEthForUbx4t = result.balance;
                      this.balanceEthForUbxcf = result.balance;

                    } else {
                      this.balanceEthForUbxp = null;
                      this.balanceEthForUbx4t = null;
                      this.balanceEthForUbxcf = null;
                    }

                    this.adminClient
                      .GetEthBalance('0x3cb16397db59014884d2c666de5cd6f9fe49956c', this.authService.$userId.value)
                      .subscribe(result => {

                        if (result !== null) {
                          this.balanceEthForUbsn = result.balance;

                        } else {
                          this.balanceEthForUbsn = null;
                        }

                        this.adminClient
                          .GetCilBalance('Uxc6f24761dc38797c7140857ea2ebf7b65459e768', this.authService.$userId.value)
                          .subscribe(result => {

                            if (result !== null) {
                              this.balanceUbxForCdr = result.balance;

                            } else {
                              this.balanceUbxForCdr = null;
                            }

                            this.adminClient
                              .GetCilBalance('Uxbfcfb366fe469e4ded60f0adcd174f4dff998a2d', this.authService.$userId.value)
                              .subscribe(result => {

                                if (result !== null) {
                                  this.balanceUbxForLse = result.balance;

                                } else {
                                  this.balanceUbxForLse = null;
                                }

                                this.adminClient
                                  .GetCilBalance('Uxd707ba63c25b2863339820fe1e66b0ee6c980f18', this.authService.$userId.value)
                                  .subscribe(result => {

                                    if (result !== null) {
                                      this.balanceUbxForMmm = result.balance;

                                    } else {
                                      this.balanceUbxForMmm = null;
                                    }

                                    this.adminClient
                                      .GetCilBalance('Ux85d945b0386883880dd28fa6f52cc29c0483bdfa', this.authService.$userId.value)
                                      .subscribe(result => {

                                        if (result !== null) {
                                          this.balanceUbxForUbsn = result.balance;

                                        } else {
                                          this.balanceUbxForUbsn = null;
                                        }

                                        this.adminClient
                                          .GetCilBalance('Ux8898b38102cc665070fbe41fa8ecb9f933820138', this.authService.$userId.value)
                                          .subscribe(result => {

                                            if (result !== null) {
                                              this.balanceUbxForWaf = result.balance;
                                            } else {
                                              this.balanceUbxForWaf = null;
                                             }

                                            this.adminClient
                                              .GetCilBalance('Uxaac75a4aab0ade79dfb80cc5afb8bbbef6196cac', this.authService.$userId.value)
                                              .subscribe(result => {

                                                if (result !== null) {
                                                  this.balanceUbxForTks = result.balance;
                                                } else {
                                                  this.balanceUbxForTks = null;
                                                }

                                                this.adminClient
                                                  .GetCilBalance('Ux72db41bef45fec87f24e58724d0ab5c6fbdc2f1c', this.authService.$userId.value)
                                                  .subscribe(result => {

                                                    if (result !== null) {
                                                      this.balanceUbxForCdf = result.balance;
                                                    } else {
                                                      this.balanceUbxForCdf = null;
                                                    }

                                                  }, error => {
                                                    this.balanceUbxForCdf = null;

                                                    this.showError("Error while make request to server UBX!");
                                                  });

                                              }, error => {
                                                this.balanceUbxForTks = null;
                                                this.balanceUbxForCdf = null;

                                                this.showError("Error while make request to server UBX!");
                                              });

                                          }, error => {
                                            this.balanceUbxForWaf = null;
                                            this.balanceUbxForTks = null;
                                            this.balanceUbxForCdf = null;

                                            this.showError("Error while make request to server UBX!");
                                          });

                                      }, error => {
                                        this.balanceUbxForUbsn = null;
                                        this.balanceUbxForWaf = null;
                                        this.balanceUbxForTks = null;
                                        this.balanceUbxForCdf = null;

                                        this.showError("Error while make request to server UBX!");
                                      });

                                  }, error => {
                                    this.balanceUbxForMmm = null;
                                    this.balanceUbxForUbsn = null;
                                    this.balanceUbxForWaf = null;
                                    this.balanceUbxForTks = null;
                                    this.balanceUbxForCdf = null;

                                    this.showError("Error while make request to server UBX!");
                                  });

                              }, error => {
                                this.balanceUbxForLse = null;
                                this.balanceUbxForMmm = null;
                                this.balanceUbxForUbsn = null;
                                this.balanceUbxForWaf = null;
                                this.balanceUbxForTks = null;
                                this.balanceUbxForCdf = null;

                                this.showError("Error while make request to server UBX!");
                              });

                          }, error => {
                            this.balanceUbxForCdr = null;
                            this.balanceUbxForLse = null;
                            this.balanceUbxForMmm = null;
                            this.balanceUbxForUbsn = null;
                            this.balanceUbxForWaf = null;
                            this.balanceUbxForTks = null;
                            this.balanceUbxForCdf = null;

                            this.showError("Error while make request to server UBX!");
                          });

                      }, error => {
                        this.balanceEthForUbsn = null;
                        this.balanceUbxForCdr = null;
                        this.balanceUbxForLse = null;
                        this.balanceUbxForMmm = null;
                        this.balanceUbxForUbsn = null;
                        this.balanceUbxForWaf = null;
                        this.balanceUbxForTks = null;
                        this.balanceUbxForCdf = null;

                        this.showError("Error while make request to server ETH!");
                      });

                  }, error => {
                    this.balanceEthForUbxp = null;
                    this.balanceEthForUbx4t = null;
                    this.balanceEthForUbxcf = null;
                    this.balanceEthForUbsn = null;
                    this.balanceUbxForCdr = null;
                    this.balanceUbxForLse = null;
                    this.balanceUbxForMmm = null;
                    this.balanceUbxForUbsn = null;
                    this.balanceUbxForWaf = null;
                    this.balanceUbxForTks = null;
                    this.balanceUbxForCdf = null;

                    this.showError("Error while make request to server ETH!");
                  });

              }, error => {
                this.balanceEthForUbcdr = null;
                this.balanceEthForUbxe = null;
                this.balanceEthForUbxm = null;
                this.balanceEthForUbxp = null;
                this.balanceEthForUbx4t = null;
                this.balanceEthForUbxcf = null;
                this.balanceEthForUbsn = null;
                this.balanceUbxForCdr = null;
                this.balanceUbxForLse = null;
                this.balanceUbxForMmm = null;
                this.balanceUbxForUbsn = null;
                this.balanceUbxForWaf = null;
                this.balanceUbxForTks = null;
                this.balanceUbxForCdf = null;

                this.showError("Error while make request to server ETH!");
              });

          }, error => {
            this.balanceEthForUbx = null;
            this.balanceEthForUbcdr = null;
            this.balanceEthForUbxe = null;
            this.balanceEthForUbxm = null;
            this.balanceEthForUbxp = null;
            this.balanceEthForUbx4t = null;
            this.balanceEthForUbxcf = null;
            this.balanceEthForUbsn = null;
            this.balanceUbxForCdr = null;
            this.balanceUbxForLse = null;
            this.balanceUbxForMmm = null;
            this.balanceUbxForUbsn = null;
            this.balanceUbxForWaf = null;
            this.balanceUbxForTks = null;
            this.balanceUbxForCdf = null;

            this.showError("Error while make request to server ETH!");
          });

        this.adminClient
          .GetEthTokenBalance('UBX', this.authService.$userId.value)
          .subscribe(result => {

            if (result !== null) {
              this.balanceErc20Ubx = result.balance;
            } else {
              this.balanceErc20Ubx = null;
            }

            this.adminClient
              .GetEthTokenBalance('UBCDR', this.authService.$userId.value)
              .subscribe(result => {

                if (result !== null) {
                  this.balanceErc20Ubcdr = result.balance;
                } else {
                  this.balanceErc20Ubcdr = null;
                }

                this.adminClient
                  .GetEthTokenBalance('UBXE', this.authService.$userId.value)
                  .subscribe(result => {

                    if (result !== null) {
                      this.balanceErc20Ubxe = result.balance;
                    } else {
                      this.balanceErc20Ubxe = null;
                    }

                    this.adminClient
                      .GetEthTokenBalance('UBXM', this.authService.$userId.value)
                      .subscribe(result => {

                        if (result !== null) {
                          this.balanceErc20Ubxm = result.balance;
                        } else {
                          this.balanceErc20Ubxm = null;
                        }

                        this.adminClient
                          .GetEthTokenBalance('UBSN', this.authService.$userId.value)
                          .subscribe(result => {

                            if (result !== null) {
                              this.balanceErc20Ubsn = result.balance;
                            } else {
                              this.balanceErc20Ubsn = null;
                            }

                            this.adminClient
                              .GetEthTokenBalance('UBXP', this.authService.$userId.value)
                              .subscribe(result => {

                                if (result !== null) {
                                  this.balanceErc20Ubxp = result.balance;
                                } else {
                                  this.balanceErc20Ubxp = null;
                                }

                                this.adminClient
                                  .GetEthTokenBalance('UBX4T', this.authService.$userId.value)
                                  .subscribe(result => {

                                    if (result !== null) {
                                      this.balanceErc20Ubx4t = result.balance;
                                    } else {
                                      this.balanceErc20Ubx4t = null;
                                    }

                                    this.adminClient
                                      .GetEthTokenBalance('UBXCF', this.authService.$userId.value)
                                      .subscribe(result => {

                                        if (result !== null) {
                                          this.balanceErc20Ubxcf = result.balance;
                                        } else {
                                          this.balanceErc20Ubxcf = null;
                                        }


                                        this.adminClient
                                          .GetCilBalance('Uxb3fb48a1e11d29d941d910be8f5f670a94588df8', this.authService.$userId.value)
                                          .subscribe(result => {

                                            if (result !== null) {
                                              this.balanceUbixUbx = result.balance;
                                            } else {
                                              this.balanceUbixUbx = null;
                                            }

                                            this.adminClient
                                              .GetCilTokenBalance('Uxc6f24761dc38797c7140857ea2ebf7b65459e768', 'CDR', this.authService.$userId.value)
                                              .subscribe(result => {

                                                if (result !== null) {
                                                  this.balanceUbixCdr = result.balance;
                                                } else {
                                                  this.balanceUbixCdr = null;
                                                }

                                                this.adminClient
                                                  .GetCilTokenBalance('Uxbfcfb366fe469e4ded60f0adcd174f4dff998a2d', 'LSE', this.authService.$userId.value)
                                                  .subscribe(result => {

                                                    if (result !== null) {
                                                      this.balanceUbixLse = result.balance;
                                                    } else {
                                                      this.balanceUbixLse = null;
                                                    }

                                                    this.adminClient
                                                      .GetCilTokenBalance('Uxd707ba63c25b2863339820fe1e66b0ee6c980f18', 'MMM', this.authService.$userId.value)
                                                      .subscribe(result => {

                                                        if (result !== null) {
                                                          this.balanceUbixMmm = result.balance;
                                                        } else {
                                                          this.balanceUbixMmm = null;
                                                        }

                                                        this.adminClient
                                                          .GetCilTokenBalance('Ux85d945b0386883880dd28fa6f52cc29c0483bdfa', 'UBSN', this.authService.$userId.value)
                                                          .subscribe(result => {

                                                            if (result !== null) {
                                                              this.balanceUbixUbsn = result.balance;
                                                            } else {
                                                              this.balanceUbixUbsn = null;
                                                            }

                                                            this.adminClient
                                                              .GetCilTokenBalance('Ux8898b38102cc665070fbe41fa8ecb9f933820138', 'WAF', this.authService.$userId.value)
                                                              .subscribe(result => {

                                                                if (result !== null) {
                                                                  this.balanceUbixWaf = result.balance;
                                                                } else {
                                                                  this.balanceUbixWaf = null;
                                                                }

                                                                this.adminClient
                                                                  .GetCilTokenBalance('Uxaac75a4aab0ade79dfb80cc5afb8bbbef6196cac', 'TKS', this.authService.$userId.value)
                                                                  .subscribe(result => {

                                                                    if (result !== null) {
                                                                      this.balanceUbixTks = result.balance;
                                                                    } else {
                                                                      this.balanceUbixTks = null;
                                                                    }

                                                                    this.adminClient
                                                                      .GetCilTokenBalance('Ux72db41bef45fec87f24e58724d0ab5c6fbdc2f1c', 'CDF', this.authService.$userId.value)
                                                                      .subscribe(result => {

                                                                        if (result !== null) {
                                                                          this.balanceUbixCdf = result.balance;
                                                                        } else {
                                                                          this.balanceUbixCdf = null;
                                                                        }

                                                                        this.loaderService.$showLoader.next(false);

                                                                      }, error => {
                                                                        this.loaderService.$showLoader.next(false);

                                                                        this.balanceUbixCdf = null;

                                                                        this.showError("Error while make request to server CDF (UBIX)!");
                                                                      });

                                                                  }, error => {
                                                                    this.loaderService.$showLoader.next(false);

                                                                    this.balanceUbixTks = null;
                                                                    this.balanceUbixCdf = null;

                                                                    this.showError("Error while make request to server TKS (UBIX)!");
                                                                  });


                                                              }, error => {
                                                                this.loaderService.$showLoader.next(false);

                                                                this.balanceUbixWaf = null;
                                                                this.balanceUbixTks = null;
                                                                this.balanceUbixCdf = null;

                                                                this.showError("Error while make request to server WAF (UBIX)!");
                                                              });



                                                          }, error => {
                                                            this.loaderService.$showLoader.next(false);

                                                            this.balanceUbixUbsn = null;
                                                            this.balanceUbixWaf = null;
                                                            this.balanceUbixTks = null;
                                                            this.balanceUbixCdf = null;

                                                            this.showError("Error while make request to server UBSN (UBIX)!");
                                                          });

                                                      }, error => {
                                                        this.loaderService.$showLoader.next(false);

                                                        this.balanceUbixMmm = null;
                                                        this.balanceUbixUbsn = null;
                                                        this.balanceUbixWaf = null;
                                                        this.balanceUbixTks = null;
                                                        this.balanceUbixCdf = null;

                                                        this.showError("Error while make request to server MMM (UBIX)!");
                                                      });

                                                  }, error => {
                                                    this.loaderService.$showLoader.next(false);

                                                    this.balanceUbixLse = null;
                                                    this.balanceUbixMmm = null;
                                                    this.balanceUbixUbsn = null;
                                                    this.balanceUbixWaf = null;
                                                    this.balanceUbixTks = null;
                                                    this.balanceUbixCdf = null;

                                                    this.showError("Error while make request to server LSE (UBIX)!");
                                                  });

                                              }, error => {
                                                this.loaderService.$showLoader.next(false);

                                                this.balanceUbixCdr = null;
                                                this.balanceUbixLse = null;
                                                this.balanceUbixMmm = null;
                                                this.balanceUbixUbsn = null;
                                                this.balanceUbixWaf = null;
                                                this.balanceUbixTks = null;
                                                this.balanceUbixCdf = null;

                                                this.showError("Error while make request to server CDR (UBIX)!");
                                              });

                                          }, error => {
                                            this.loaderService.$showLoader.next(false);

                                            this.balanceUbixUbx = null;
                                            this.balanceUbixCdr = null;
                                            this.balanceUbixLse = null;
                                            this.balanceUbixMmm = null;
                                            this.balanceUbixUbsn = null;
                                            this.balanceUbixWaf = null;
                                            this.balanceUbixTks = null;
                                            this.balanceUbixCdf = null;

                                            this.showError("Error while make request to server UBX (UBIX)!");
                                          });

                                      }, error => {
                                        this.loaderService.$showLoader.next(false);

                                        this.balanceErc20Ubxcf = null;

                                        this.balanceUbixUbx = null;
                                        this.balanceUbixCdr = null;
                                        this.balanceUbixLse = null;
                                        this.balanceUbixMmm = null;
                                        this.balanceUbixUbsn = null;
                                        this.balanceUbixWaf = null;
                                        this.balanceUbixTks = null;
                                        this.balanceUbixCdf = null;

                                        this.showError("Error while make request to server UBSN (ERC-20)!");
                                      });

                                  }, error => {
                                    this.loaderService.$showLoader.next(false);

                                    this.balanceErc20Ubx4t = null;
                                    this.balanceErc20Ubxcf = null;

                                    this.balanceUbixUbx = null;
                                    this.balanceUbixCdr = null;
                                    this.balanceUbixLse = null;
                                    this.balanceUbixMmm = null;
                                    this.balanceUbixUbsn = null;
                                    this.balanceUbixWaf = null;
                                    this.balanceUbixTks = null;
                                    this.balanceUbixCdf = null;

                                    this.showError("Error while make request to server UBSN (ERC-20)!");
                                  });

                              }, error => {
                                this.loaderService.$showLoader.next(false);

                                this.balanceErc20Ubxp = null;
                                this.balanceErc20Ubx4t = null;
                                this.balanceErc20Ubxcf = null;

                                this.balanceUbixUbx = null;
                                this.balanceUbixCdr = null;
                                this.balanceUbixLse = null;
                                this.balanceUbixMmm = null;
                                this.balanceUbixUbsn = null;
                                this.balanceUbixWaf = null;
                                this.balanceUbixTks = null;
                                this.balanceUbixCdf = null;

                                this.showError("Error while make request to server UBSN (ERC-20)!");
                              });


                          }, error => {
                            this.loaderService.$showLoader.next(false);

                            this.balanceErc20Ubsn = null;

                            this.balanceErc20Ubxp = null;
                            this.balanceErc20Ubx4t = null;
                            this.balanceErc20Ubxcf = null;

                            this.balanceUbixUbx = null;
                            this.balanceUbixCdr = null;
                            this.balanceUbixLse = null;
                            this.balanceUbixMmm = null;
                            this.balanceUbixUbsn = null;
                            this.balanceUbixWaf = null;
                            this.balanceUbixTks = null;
                            this.balanceUbixCdf = null;

                            this.showError("Error while make request to server UBSN (ERC-20)!");
                          });

                      }, error => {
                        this.loaderService.$showLoader.next(false);

                        this.balanceErc20Ubxm = null;
                        this.balanceErc20Ubsn = null;

                        this.balanceErc20Ubxp = null;
                        this.balanceErc20Ubx4t = null;
                        this.balanceErc20Ubxcf = null;

                        this.balanceUbixUbx = null;
                        this.balanceUbixCdr = null;
                        this.balanceUbixLse = null;
                        this.balanceUbixMmm = null;
                        this.balanceUbixUbsn = null;
                        this.balanceUbixWaf = null;
                        this.balanceUbixTks = null;
                        this.balanceUbixCdf = null;

                        this.showError("Error while make request to server UBXM (ERC-20)!");
                      });

                  }, error => {
                    this.loaderService.$showLoader.next(false);

                    this.balanceErc20Ubxe = null;
                    this.balanceErc20Ubxm = null;
                    this.balanceErc20Ubsn = null;

                    this.balanceErc20Ubxp = null;
                    this.balanceErc20Ubx4t = null;
                    this.balanceErc20Ubxcf = null;

                    this.balanceUbixUbx = null;
                    this.balanceUbixCdr = null;
                    this.balanceUbixLse = null;
                    this.balanceUbixMmm = null;
                    this.balanceUbixUbsn = null;
                    this.balanceUbixWaf = null;
                    this.balanceUbixTks = null;
                    this.balanceUbixCdf = null;

                    this.showError("Error while make request to server UBXE (ERC-20)!");
                  });

              }, error => {
                this.loaderService.$showLoader.next(false);

                this.balanceErc20Ubcdr = null;
                this.balanceErc20Ubxe = null;
                this.balanceErc20Ubxm = null;
                this.balanceErc20Ubsn = null;

                this.balanceErc20Ubxp = null;
                this.balanceErc20Ubx4t = null;
                this.balanceErc20Ubxcf = null;

                this.balanceUbixUbx = null;
                this.balanceUbixCdr = null;
                this.balanceUbixLse = null;
                this.balanceUbixMmm = null;
                this.balanceUbixUbsn = null;
                this.balanceUbixWaf = null;
                this.balanceUbixTks = null;
                this.balanceUbixCdf = null;

                this.showError("Error while make request to server UBCDR (ERC-20)!");
              });


          }, error => {
            this.loaderService.$showLoader.next(false);

            this.balanceErc20Ubx = null;
            this.balanceErc20Ubcdr = null;
            this.balanceErc20Ubxe = null;
            this.balanceErc20Ubxm = null;
            this.balanceErc20Ubsn = null;

            this.balanceErc20Ubxp = null;
            this.balanceErc20Ubx4t = null;
            this.balanceErc20Ubxcf = null;

            this.balanceUbixUbx = null;
            this.balanceUbixCdr = null;
            this.balanceUbixLse = null;
            this.balanceUbixMmm = null;
            this.balanceUbixUbsn = null;
            this.balanceUbixWaf = null;
            this.balanceUbixTks = null;
            this.balanceUbixCdf = null;

            this.showError("Error while make request to server UBX (ERC-20)!");
          });

      } else {

        this.loaderService.$showLoader.next(false);
        console.log("User not Authenticated!")

        this.options = null;

        this.balanceErc20Ubx = null;
        this.balanceErc20Ubcdr = null;
        this.balanceErc20Ubxe = null;
        this.balanceErc20Ubxm = null;
        this.balanceErc20Ubsn = null;

        this.balanceErc20Ubxp = null;
        this.balanceErc20Ubx4t = null;
        this.balanceErc20Ubxcf = null;

        this.balanceUbixUbx = null;
        this.balanceUbixCdr = null;
        this.balanceUbixLse = null;
        this.balanceUbixMmm = null;
        this.balanceUbixUbsn = null;
        this.balanceUbixWaf = null;
        this.balanceUbixTks = null;
        this.balanceUbixCdf = null;

        this.showError("User not Authenticated!", false);
      }

  }



  showError(message: String, isError: boolean = true): void {
    this.dialog.open(ErrorFormComponent, {
      width: '440px',
      data: {'message': message, 'isError': isError}
    });
  }

}
