import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { BehaviorSubject, catchError, firstValueFrom, Observable, of } from "rxjs";
import { SupportChatDialogView } from "../../../models/Chat/supportChatDialogView";
import { FormControl, Validators } from "@angular/forms";
import { IdentityClient, JwtTokenResponse, SignInRequest, UserResponse } from "../../../api/IdentityClient";
import { environment } from "../../../../environments/environment";
import { SupportChatApiClient } from "../../../api/supportChatApiClient";
import { SupportChatNewMsgResponse } from "../../../models/Chat/supportChatNewMsgResponse";
import { SupportChatDialogMessagesResponse } from "../../../models/Chat/supportChatDialogMessagesResponse";
import { SupportChatUserDialogsResponse } from "../../../models/Chat/supportChatUserDialogsResponse";
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType } from "@angular/common/http";
import { CurrentUser } from "../../../models/Chat/currentUser";
import { ErrorFormComponent } from "../../../components/error-form/error-form.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  @ViewChild('supportTrigger') supportTrigger: MatMenuTrigger;
  @ViewChild('chatTrigger') chatTrigger: MatMenuTrigger;
  @ViewChild('chat') chat: MatMenu;

  dialogs: SupportChatDialogView[] = []

  messages: any[] = []

  activeChatId: number | null = null;
  activeChatDialog: SupportChatDialogView | null = null;
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isUserEmail: BehaviorSubject<string> = new BehaviorSubject<string>('');
  newMessages: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  supportChatCurrentUser: BehaviorSubject<UserResponse> = new BehaviorSubject<UserResponse>(null);

  formControlEmail: FormControl = new FormControl();
  formControlPassword: FormControl = new FormControl();

  projectCode: string;

  private authResult: JwtTokenResponse;
  private timerId1: number;
  loadingProgress: number;
  sending$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  messagesSent$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private identityApi: IdentityClient,
              private supportChatApi: SupportChatApiClient,
              @Inject(HttpClient) private httpClient: HttpClient,
              public dialog: MatDialog) {
    this.formControlEmail.setValidators([Validators.required, Validators.email]);
    this.formControlPassword.setValidators([Validators.required]);

    this.projectCode = environment.projectCode;

    this.supportChatCurrentUser.subscribe(s => {
      this.supportChatApi.updateAuth(this.authResult?.access_token);
      if (s) {
        this.isAuthenticated.next(true);
      } else {
        this.isAuthenticated.next(false);
      }
    });

    this.isAuthenticated.subscribe(async s => {
      if (s) {
        this.supportChatApi.updateAuth(this.authResult.access_token);
        await this.getDialogs();
      } else {
        this.supportChatApi.updateAuth(null);
        await this.clearDialogs();
        this.isUserEmail.next(null);
      }
    });
  }

  async ngOnInit() {
    let access_token = localStorage.getItem("support_chat_current_user_access_token");
    let authUser = this.parseToken(access_token);
    if (access_token && authUser) {
      this.authResult = new JwtTokenResponse({
        access_token: access_token,
        refresh_token: localStorage.getItem("support_chat_current_user_refresh_token"),
        expires_in: authUser.exp
      })

      this.supportChatApi.updateAuth(this.authResult.access_token);

      this.isUserEmail.next(authUser.user.email); // +

      this.isAuthenticated.next(true);

    } else {
      localStorage.setItem("support_chat_current_user_refresh_token", null);

      console.log('nginit: FALSE');
    }

    await this.setTimer();
  }

  private async setTimer() {
    // @ts-ignore
    this.timerId1 = setTimeout(async () => {
      if (this.activeChatId) {
        await this.getMessages(this.activeChatId);
      }
      await this.setTimer();
    }, 2000);
  }

  closeDialogs() {
    this.supportTrigger.closeMenu();
  }

  async openChat(id: number | null) {
    this.chatTrigger.openMenu();
    this.activeChatId = id;
    this.activeChatDialog = this.dialogs.find(s => s.Id == id);
    if (this.activeChatDialog) {
      const response = await this.getMessages(id);
      this.messages = response?.data ?? [];
    } else
      this.messages = [];
  }

  closeChat() {
    this.chatTrigger.closeMenu();
    this.activeChatId = null;
    this.activeChatDialog = null;
    this.messages = [];
  }

  ngAfterViewInit(): void {
    this.chat.setElevation(4)
  }

  async authenticate() {
    if (this.formControlPassword.invalid || this.formControlEmail.invalid) {
      alert("Email and password is required");
      return;
    }
    let request = new SignInRequest(
      {email: this.formControlEmail.value, password: this.formControlPassword.value}
    );
    this.identityApi.createJwt(request).subscribe(data => {
      this.authResult = data;

      if (this.authResult?.access_token) {
        this.isUserEmail.next(request.email);
        localStorage.setItem("support_chat_current_user_access_token", this.authResult.access_token);
        localStorage.setItem("support_chat_current_user_refresh_token", this.authResult.refresh_token);
        this.supportChatCurrentUser.next(this.authResult);
      }


    }, error => {
      // console.log(error.message ?? 'Error');
      this.showError(error.message ?? 'Authentication error!');
    });


  }

  async getDialogs() {
    let result = await firstValueFrom(
      this.supportChatApi.get<SupportChatUserDialogsResponse>(`/dialogs/user?projectCode=${this.projectCode}`));
    this.dialogs = result.data;
  }

  async clearDialogs() {
    this.dialogs = [];
    await this.clearActiveDialog();
  }

  async clearActiveDialog() {
    this.activeChatId = null;
    this.activeChatDialog = null
    this.messages = [];
  }

  async getMessages(dialogId: number) {
    if (dialogId) {
      this.activeChatId = dialogId;
      const response = await firstValueFrom(
        this.supportChatApi.get<SupportChatDialogMessagesResponse>(`/messages/dialog/${dialogId ?? null}?projectCode=${this.projectCode}`));
      this.messages = response.data;
      return response;
    }
    return null;
  }

  async sendMessage(dialogId: number | null, message: { text: string, files: File[] }) {
    if ((message.files == null || message.files.length == 0) &&
      (message.text == null || message.text.length == 0)) {
      alert("Message text is required")
      // @ts-ignore
      return;
    }
    this.sending$.next(true);


    if (message.files?.length > 0)
      return await this.sendImage(message.files, message.text, dialogId)
    await this.sendText(message.text, dialogId);
  }

  async sendText(text: string, dialogId: number) {
    const response = await firstValueFrom(
      this.supportChatApi.post<SupportChatNewMsgResponse>('/messages/create', {
        nnDialog: dialogId ?? null,
        text,
        projectCode: this.projectCode
      }));
    this.sending$.next(false);
    this.messagesSent$.next(true);
    await this.getMessages(response.data.DialogId);

    if (dialogId == null)
      await this.getDialogs();
  }

  async sendImage(images: File[], text: string, dialogId: number) {
    let formData = new FormData();
    let size = 0;
    let maxSize = 1024 * 1024;
    images.forEach((img) => {
      formData.append("images", img);
      size += img.size;
    });
    if (size > maxSize) {
      this.sending$.next(false);
      return alert(`Max attachments size: ${maxSize / 1024} KB`);
    }
    const params = {
      nnDialog: dialogId,
      projectCode: this.projectCode
    };

    const uploadImage = this.supportChatApi.upload<any>('/messages/images', formData, {...params});
    await this.imageUploadingSub(uploadImage, async (currentDialogId: number) => {
      if (text) {
        await this.sendText(text, currentDialogId)
      } else {
        this.messagesSent$.next(true)
        this.sending$.next(false)
      }
    });
  }

  private async imageUploadingSub(sub: Observable<HttpEvent<any>>, completeCallback: (currentDialogId: number) => void) {
    sub.pipe(catchError((err: HttpErrorResponse, c) => {
      this.loadingProgress = 0;
      this.sending$.next(false);
      alert('Error uploading attachments')
      return of(null);
    })).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.Sent:
            this.loadingProgress = 0;
            break;
          case HttpEventType.Response:
            if (event.status === 200) {
              completeCallback(event.body.DialogId);
            }
            break;
          case HttpEventType.UploadProgress:
            this.loadingProgress = event['loaded'] / event['total'] * 100;
            if (this.loadingProgress == 100) {
              this.loadingProgress = 0;
            }
            break;
          default:
            this.loadingProgress = 0;
            break;
        }
        return event;
      }
    )
  }


  async createDialog() {
    await this.clearActiveDialog();
  }

  // @ts-ignore
  private parseToken(accessToken: string) {
    if (accessToken == null)
      return null;
    const obj = JSON.parse(atob(accessToken.split('.')[1]));
    const expDate = new Date(0);
    expDate.setUTCSeconds(obj.exp);
    if (expDate > new Date()) {
      const type = parseInt(obj.type, 10);

      const user: CurrentUser = <CurrentUser>{
        email: obj.sub,
      }
      this.isUserEmail.next(user.email);

      const diff = expDate.getTime() - new Date().getTime();
      return {diff: diff, user, expDate, exp: obj.exp};
    }
  }

  showError(message: String): void {
    this.dialog.open(ErrorFormComponent, {
      width: '440px',
      data: message
    });
  }
}
