import { Component } from '@angular/core';
import * as MobileDetect from 'mobile-detect';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  mobileDetect = new MobileDetect(window.navigator.userAgent);


  goTelegram() {
    window.open("https://t.me/CDR_PM", "_blank");
  }

  goUbixNetwork() {
    window.open("https://ubix.network", "_blank");
  }
}
