import { Injectable } from '@angular/core';
import {BehaviorSubject, timer} from "rxjs";
import {TransitClient} from "../api/api.client.generated";
import {AuthService} from "../admin/shared/services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class CounterService {

  public $counter: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  constructor(private transitClient: TransitClient,
              private authService: AuthService) { }

  updateCounter(): void {
    if (this.authService.$userId.value === '') {
      this.authService.$userId.next(this.authService.create_UUID());
    }

    this.transitClient
      .getCount(this.authService.$userId.value)
      .subscribe(count => {
        this.$counter.next(count);
      }, error => {
        this.$counter.next(null);
      });

  }
}
