import { WalletDto } from './WalletDto';
import { KeyPair } from '../Cil/lib/keyPair';
import { CryptoLib } from '../Cil/lib/crypto';
import { Utils } from '../Cil/lib/utils';

export class CilWalletDto extends WalletDto {
  private wallet: KeyPair;
  override fileVersion = 1.2;

  constructor(privateKey: string = null, keyFile: string = null) {
    super(privateKey, keyFile);
    if (privateKey) {
      this.wallet = KeyPair.keyPairFromPrivate(privateKey);
    }
    else if (keyFile) {
      this.wallet = <KeyPair>{};
    }
    else {
      this.wallet = KeyPair.createKeyPair();
    }

    this.address = this.wallet.address;
    this.publicKey = this.wallet.publicKey;
    this.privateKey = this.wallet.privateKey;
  }

  async encrypt(password: string): Promise<void> {
    const objEncryptedPk = await CryptoLib.encrypt(this.privateKey, password);
    let walletDtoPrepare = {
      address: 'Ux' + this.address,
      ...Utils.prepareForStringifyObject(objEncryptedPk),
      version: this.fileVersion
    };
    let keyFile = this.addHash(walletDtoPrepare);
    this.keyFile = JSON.stringify(keyFile);
  }

  async decrypt(password: string): Promise<void> {
    const privateKey = (await CryptoLib.decrypt(this.keyFile, password)).toString();
    this.wallet = KeyPair.keyPairFromPrivate(privateKey);
    this.address = this.wallet.address;
    this.publicKey = this.wallet.publicKey;
    this.privateKey = this.wallet.privateKey;
  }

  sign(tran: any): Promise<string> {
    return undefined;
  }

  getAddress(): string {
    return 'Ux' + this.wallet.address;
  }

  getKeyFileName(): string {
    return 'UBX-KeyStore-' + new Date().toISOString();
  }
}
