import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";

@Component({
  selector: 'app-access-with-keystore',
  templateUrl: './access-with-keystore.component.html',
  styleUrls: ['./access-with-keystore.component.scss']
})
export class AccessWithKeystoreComponent {
  @Input() error: string;
  @Output() password = new EventEmitter<string>();

  showPwd = false;

  passwordForm = this.formBuilder.group({
    password: ['', [Validators.required]]
  });

  constructor(private formBuilder: FormBuilder) {
  }

  onSubmit() {
    if (this.passwordForm.invalid) {
      return;
    }
    this.password.emit(this.passwordForm.value.password);
  }
}
