import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public $userId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public $isAuth: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public $isAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
  }

  get token(): string {
    return ''
  }

  create_UUID(): string {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }


  logout() {

  }

  isAuthenticated(): boolean {
    return !!this.token
  }

  private setToken(data: any) {
    console.log('data: ', data)
  }

}
