import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {PairDto, TransitClient} from "../../../api/api.client.generated";
import {Router} from "@angular/router";
import {PairService} from "../../../services/pair.service";
import {SpinnerService} from "../../../services/spinner.service";
import {CustomValidators} from "../../../helpers/CustomValidators";

@Component({
  selector: 'app-create-swap',
  templateUrl: './create-swap.component.html',
  styleUrls: ['./create-swap.component.scss']
})
export class CreateSwapComponent implements OnInit {
  public pair: PairDto;

  swapAddress = this.formBuilder.group({
    walletAddress: ['', [Validators.required, Validators.minLength(42)]]
  })

  constructor(protected formBuilder: FormBuilder,
              private httpClient: TransitClient,
              private router: Router,
              private pairService: PairService,
              private spinnerService: SpinnerService) {
  }

  ngOnInit(): void {
    this.pairService
      .$currentPair
      .subscribe(pair => {
        if (pair != null) {
          this.pair = pair;
          this.updateValidators();
        }
      });

    this.pairService.$holdPair.next(false);
  }

  createSwap() {
    if (!this.swapAddress.valid) {
      return;
    }

    if (!this.pair) {
      return;
    }

    this.spinnerService.$showSpinner.next(true);

    // this.httpClient
    //   .createTransit(<string>this.swapAddress.value.walletAddress, this.pair)
    //   .subscribe(transitWallet => {
    //     this.spinnerService.$showSpinner.next(false);
    //     this.router.navigate([`send/${transitWallet}`]).then();
    //   });
  }

  private updateValidators() {
    this.swapAddress.controls.walletAddress.clearValidators();
    this.swapAddress.controls.walletAddress
      .addValidators([
        Validators.required,
        Validators.minLength(42),
        CustomValidators.swapAddressValidator(this.pair.toCoin)
      ]);
    this.swapAddress.controls.walletAddress.updateValueAndValidity();
  }

  private validateAddress() {
    const address = this.swapAddress.value.walletAddress;
    if (this.pair.toCoin == "LAW") {
      // if (address.startsWith())
    }
  }
}
