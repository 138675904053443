import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-error-form',
  templateUrl: './error-form.component.html',
  styleUrls: ['./error-form.component.scss']
})
export class ErrorFormComponent {
  constructor(public dialogRef: MatDialogRef<ErrorFormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: string) {}

  closeClick(): void {
    this.dialogRef.close();
  }
}
