<h3>CDR SWAP WALLETS BALANCES</h3>
<div class="table-outer">


  <button mat-button color="primary" aria-label="Request" (click)="makeRequest()">Request</button>
  <br />
  <mat-card>
    <mat-card-content>
      <h3>BSC</h3>
      <h4>
        UBX (BEP-20):
        <span *ngIf="balanceBep20Ubx !== undefined && balanceBep20Ubx !== null" [ngClass]="{'setError': (options !== null && balanceBep20Ubx <= options.bscTokenUbx ?? 0)}">{{balanceBep20Ubx | number:'1.0-2'}}</span>
        <span *ngIf="balanceBep20Ubx === undefined || balanceBep20Ubx === null">no data</span>
        (BNB Balance:
        <span *ngIf="balanceBnbForUbx !== undefined && balanceBnbForUbx !== null" [ngClass]="{'setError': (options !== null && balanceBnbForUbx <= options.bnbForBscToken ?? 0)}">{{balanceBnbForUbx | number:'1.0-18'}}</span>
        <span *ngIf="balanceBnbForUbx === undefined || balanceBnbForUbx === null">no data</span>
        )
      </h4>
    </mat-card-content>
  </mat-card>
  <br />
  <mat-card>
    <mat-card-content>
      <h3>ETHEREUM</h3>
      <h4>
        UBX (ERC-20):
        <span *ngIf="balanceErc20Ubx !== undefined && balanceErc20Ubx !== null" [ngClass]="{'setError': (options !== null && balanceErc20Ubx <= options.ethTokenUbx ?? 0)}">{{balanceErc20Ubx | number:'1.0-2'}}</span>
        <span *ngIf="balanceErc20Ubx === undefined || balanceErc20Ubx === null">no data</span>
        (ETH Balance:
        <span *ngIf="balanceEthForUbx !== undefined && balanceEthForUbx !== null" [ngClass]="{'setError': (options !== null && balanceEthForUbx <= options.ethForEthToken ?? 0)}">{{balanceEthForUbx | number:'1.0-18'}}</span>
        <span *ngIf="balanceEthForUbx === undefined || balanceEthForUbx === null">no data</span>
        )
      </h4>
      <h4>
        UBCDR (ERC-20):
        <span *ngIf="balanceErc20Ubcdr !== undefined && balanceErc20Ubcdr !== null" [ngClass]="{'setError': (options !== null && balanceErc20Ubcdr <= options.ethTokenUbcdr ?? 0)}">{{balanceErc20Ubcdr | number:'1.0-2'}}</span>
        <span *ngIf="balanceErc20Ubcdr === undefined || balanceErc20Ubcdr === null">no data</span>
        (ETH Balance:
        <span *ngIf="balanceEthForUbcdr !== undefined && balanceEthForUbcdr !== null" [ngClass]="{'setError': (options !== null && balanceEthForUbcdr <= options.ethForEthToken ?? 0)}">{{balanceEthForUbcdr | number:'1.0-18'}}</span>
        <span *ngIf="balanceEthForUbcdr === undefined || balanceEthForUbcdr === null">no data</span>
        )
      </h4>
      <h4>
        UBXE (ERC-20):
        <span *ngIf="balanceErc20Ubxe !== undefined && balanceErc20Ubxe !== null" [ngClass]="{'setError': (options !== null && balanceErc20Ubxe <= options.ethTokenUbxe ?? 0)}">{{balanceErc20Ubxe | number:'1.0-2'}}</span>
        <span *ngIf="balanceErc20Ubxe === undefined || balanceErc20Ubxe === null">no data</span>
        (ETH Balance:
        <span *ngIf="balanceEthForUbxe !== undefined && balanceEthForUbxe !== null" [ngClass]="{'setError': (options !== null && balanceEthForUbxe <= options.ethForEthToken ?? 0)}">{{balanceEthForUbxe | number:'1.0-18'}}</span>
        <span *ngIf="balanceEthForUbxe === undefined || balanceEthForUbxe === null">no data</span>
        )
      </h4>
      <h4>
        UBXM (ERC-20):
        <span *ngIf="balanceErc20Ubxm !== undefined && balanceErc20Ubxm !== null" [ngClass]="{'setError': (options !== null && balanceErc20Ubxm <= options.ethTokenUbxm ?? 0)}">{{balanceErc20Ubxm | number:'1.0-2'}}</span>
        <span *ngIf="balanceErc20Ubxm === undefined || balanceErc20Ubxm === null">no data</span>
        (ETH Balance:
        <span *ngIf="balanceEthForUbxm !== undefined && balanceEthForUbxm !== null" [ngClass]="{'setError': (options !== null && balanceEthForUbxm <= options.ethForEthToken ?? 0)}">{{balanceEthForUbxm | number:'1.0-18'}}</span>
        <span *ngIf="balanceEthForUbxm === undefined || balanceEthForUbxm === null">no data</span>
        )
      </h4>
      <h4>
        UBSN (ERC-20):
        <span *ngIf="balanceErc20Ubsn !== undefined && balanceErc20Ubsn !== null" [ngClass]="{'setError': (options !== null && balanceErc20Ubsn <= options.ethTokenUbsn ?? 0)}">{{balanceErc20Ubsn | number:'1.0-2'}}</span>
        <span *ngIf="balanceErc20Ubsn === undefined || balanceErc20Ubsn === null">no data</span>
        (ETH Balance:
        <span *ngIf="balanceEthForUbsn !== undefined && balanceEthForUbsn !== null" [ngClass]="{'setError': (options !== null && balanceEthForUbsn <= options.ethForEthToken ?? 0)}">{{balanceEthForUbsn | number:'1.0-18'}}</span>
        <span *ngIf="balanceEthForUbsn === undefined || balanceEthForUbsn === null">no data</span>
        )
      </h4>
      <h4>
        UBXP (ERC-20):
        <span *ngIf="balanceErc20Ubxp !== undefined && balanceErc20Ubxp !== null" [ngClass]="{'setError': (options !== null && balanceErc20Ubxp <= options.ethTokenUbxp ?? 0)}">{{balanceErc20Ubxp | number:'1.0-2'}}</span>
        <span *ngIf="balanceErc20Ubxp === undefined || balanceErc20Ubxp === null">no data</span>
        (ETH Balance:
        <span *ngIf="balanceEthForUbxp !== undefined && balanceEthForUbxp !== null" [ngClass]="{'setError': (options !== null && balanceEthForUbxp <= options.ethForEthToken ?? 0)}">{{balanceEthForUbxp | number:'1.0-18'}}</span>
        <span *ngIf="balanceEthForUbxp === undefined || balanceEthForUbxp === null">no data</span>
        )
      </h4>
      <h4>
        UBX4T (ERC-20):
        <span *ngIf="balanceErc20Ubx4t !== undefined && balanceErc20Ubx4t !== null" [ngClass]="{'setError': (options !== null && balanceErc20Ubx4t <= options.ethTokenUbx4t ?? 0)}">{{balanceErc20Ubx4t | number:'1.0-2'}}</span>
        <span *ngIf="balanceErc20Ubx4t === undefined || balanceErc20Ubx4t === null">no data</span>
        (ETH Balance:
        <span *ngIf="balanceEthForUbx4t !== undefined && balanceEthForUbx4t !== null" [ngClass]="{'setError': (options !== null && balanceEthForUbx4t <= options.ethForEthToken ?? 0)}">{{balanceEthForUbx4t | number:'1.0-18'}}</span>
        <span *ngIf="balanceEthForUbx4t === undefined || balanceEthForUbx4t === null">no data</span>
        )
      </h4>
      <h4>
        UBXCF (ERC-20):
        <span *ngIf="balanceErc20Ubxcf !== undefined && balanceErc20Ubxcf !== null" [ngClass]="{'setError': (options !== null && balanceErc20Ubxcf <= options.ethTokenUbxcf ?? 0)}">{{balanceErc20Ubxcf | number:'1.0-2'}}</span>
        <span *ngIf="balanceErc20Ubxcf === undefined || balanceErc20Ubxcf === null">no data</span>
        (ETH Balance:
        <span *ngIf="balanceEthForUbxcf !== undefined && balanceEthForUbxcf !== null" [ngClass]="{'setError': (options !== null && balanceEthForUbxcf <= options.ethForEthToken ?? 0)}">{{balanceEthForUbxcf | number:'1.0-18'}}</span>
        <span *ngIf="balanceEthForUbxcf === undefined || balanceEthForUbxcf === null">no data</span>
        )
      </h4>
    </mat-card-content>
  </mat-card>
      <br/>
  <mat-card>
    <mat-card-content>
      <h3>UBIX</h3>
      <h4>
        UBХ (native):
        <span *ngIf="balanceUbixUbx !== undefined && balanceUbixUbx !== null" [ngClass]="{'setError': (options !== null && balanceUbixUbx <= options.ubixUbx ?? 0)}">{{balanceUbixUbx | number:'1.0-2'}}</span>
        <span *ngIf="balanceUbixUbx === undefined || balanceUbixUbx === null">no data</span>
      </h4>
      <h4>
        CDR (UBХ) (UBCDR):
        <span *ngIf="balanceUbixCdr !== undefined && balanceUbixCdr !== null" [ngClass]="{'setError': (options !== null && balanceUbixCdr <= options.ubixTokenCdr ?? 0)}">{{balanceUbixCdr | number:'1.0-2'}}</span>
        <span *ngIf="balanceUbixCdr === undefined || balanceUbixCdr === null">no data</span>
        (UBX Balance:
        <span *ngIf="balanceUbxForCdr !== undefined && balanceUbxForCdr !== null" [ngClass]="{'setError': (options !== null && balanceUbxForCdr <= options.ubxForUbixToken ?? 0)}">{{balanceUbxForCdr | number:'1.0-2'}}</span>
        <span *ngIf="balanceUbxForCdr === undefined || balanceUbxForCdr === null">no data</span>
        )
      </h4>
      <h4>
        LSE (UBХ) (UBXE):
        <span *ngIf="balanceUbixLse !== undefined && balanceUbixLse !== null" [ngClass]="{'setError': (options !== null && balanceUbixLse <= options.ubixTokenLse ?? 0)}">{{balanceUbixLse | number:'1.0-2'}}</span>
        <span *ngIf="balanceUbixLse === undefined || balanceUbixLse === null">no data</span>
        (UBX Balance:
        <span *ngIf="balanceUbxForLse !== undefined && balanceUbxForLse !== null" [ngClass]="{'setError': (options !== null && balanceUbxForLse <= options.ubxForUbixToken ?? 0)}">{{balanceUbxForLse | number:'1.0-2'}}</span>
        <span *ngIf="balanceUbxForLse === undefined || balanceUbxForLse === null">no data</span>
        )
      </h4>
      <h4>
        MMM (UBХ) (UBXM):
        <span *ngIf="balanceUbixMmm !== undefined && balanceUbixMmm !== null" [ngClass]="{'setError': (options !== null && balanceUbixMmm <= options.ubixTokenMmm ?? 0)}">{{balanceUbixMmm | number:'1.0-2'}}</span>
        <span *ngIf="balanceUbixMmm === undefined || balanceUbixMmm === null">no data</span>
        (UBX Balance:
        <span *ngIf="balanceUbxForMmm !== undefined && balanceUbxForMmm !== null" [ngClass]="{'setError': (options !== null && balanceUbxForMmm <= options.ubxForUbixToken ?? 0)}">{{balanceUbxForMmm | number:'1.0-2'}}</span>
        <span *ngIf="balanceUbxForMmm === undefined || balanceUbxForMmm === null">no data</span>
        )
      </h4>
      <h4>
        UBSN (UBX):
        <span *ngIf="balanceUbixUbsn !== undefined && balanceUbixUbsn !== null" [ngClass]="{'setError': (options !== null && balanceUbixUbsn <= options.ubixTokenUbsn ?? 0)}">{{balanceUbixUbsn | number:'1.0-2'}}</span>
        <span *ngIf="balanceUbixUbsn === undefined || balanceUbixUbsn === null">no data</span>
        (UBX Balance:
        <span *ngIf="balanceUbxForUbsn !== undefined && balanceUbxForUbsn !== null" [ngClass]="{'setError': (options !== null && balanceUbxForUbsn <= options.ubxForUbixToken ?? 0)}">{{balanceUbxForUbsn | number:'1.0-2'}}</span>
        <span *ngIf="balanceUbxForUbsn === undefined || balanceUbxForUbsn === null">no data</span>
        )
      </h4>
      <h4>
        WAF (UBХ) (UBWF):
        <span *ngIf="balanceUbixWaf !== undefined && balanceUbixWaf !== null" [ngClass]="{'setError': (options !== null && balanceUbixWaf <= options.ubixTokenWaf ?? 0)}">{{balanceUbixWaf | number:'1.0-2'}}</span>
        <span *ngIf="balanceUbixWaf === undefined || balanceUbixWaf === null">no data</span>
        (UBX Balance:
        <span *ngIf="balanceUbxForWaf !== undefined && balanceUbxForWaf !== null" [ngClass]="{'setError': (options !== null && balanceUbxForWaf <= options.ubxForUbixToken ?? 0)}">{{balanceUbxForWaf | number:'1.0-2'}}</span>
        <span *ngIf="balanceUbxForWaf === undefined || balanceUbxForWaf === null">no data</span>
        )
      </h4>
      <h4>
        TKS (UBХ) (UBX4T):
        <span *ngIf="balanceUbixTks !== undefined && balanceUbixTks !== null" [ngClass]="{'setError': (options !== null && balanceUbixTks <= options.ubixTokenTks ?? 0)}">{{balanceUbixTks | number:'1.0-2'}}</span>
        <span *ngIf="balanceUbixTks === undefined || balanceUbixTks === null">no data</span>
        (UBX Balance:
        <span *ngIf="balanceUbxForTks !== undefined && balanceUbxForTks !== null" [ngClass]="{'setError': (options !== null && balanceUbxForTks <= options.ubxForUbixToken ?? 0)}">{{balanceUbxForTks | number:'1.0-2'}}</span>
        <span *ngIf="balanceUbxForTks === undefined || balanceUbxForTks === null">no data</span>
        )
      </h4>
      <h4>
        CDF (UBХ) (UBPAY):
        <span *ngIf="balanceUbixCdf !== undefined && balanceUbixCdf !== null" [ngClass]="{'setError': (options !== null && balanceUbixCdf <= options.ubixTokenCdf ?? 0)}">{{balanceUbixCdf | number:'1.0-2'}}</span>
        <span *ngIf="balanceUbixCdf === undefined || balanceUbixCdf === null">no data</span>
        (UBX Balance:
        <span *ngIf="balanceUbxForCdf !== undefined && balanceUbxForCdf !== null" [ngClass]="{'setError': (options !== null && balanceUbxForCdf <= options.ubxForUbixToken ?? 0)}">{{balanceUbxForCdf | number:'1.0-2'}}</span>
        <span *ngIf="balanceUbxForCdf === undefined || balanceUbxForCdf === null">no data</span>
        )
      </h4>

      <br/>

    </mat-card-content>
  </mat-card>


</div>
