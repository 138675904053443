<h3>CDR OPTIONS (Minimum balances for warning message)</h3>
<div class="table-outer">

  <br />

  <mat-card>
    <mat-card-content>
      <h3>BSC</h3>
      <mat-form-field>
        <mat-label>UBX (BEP-20)</mat-label>
        <input type="number" matInput [formControl]="form.controls.bscTokenUbx">
        <mat-hint *ngIf="form.controls.bscTokenUbx.invalid"></mat-hint>
      </mat-form-field>
    </mat-card-content>
  </mat-card>

  <br />

  <mat-card>
    <mat-card-content>
      <h3>ETHEREUM</h3>
      <mat-form-field>
        <mat-label>UBX (ERC-20)</mat-label>
        <input type="number" matInput [formControl]="form.controls.ethTokenUbx">
        <mat-hint *ngIf="form.controls.ethTokenUbx.invalid"></mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>UBCDR (ERC-20)</mat-label>
        <input type="number" matInput [formControl]="form.controls.ethTokenUbcdr">
        <mat-hint *ngIf="form.controls.ethTokenUbcdr.invalid"></mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>UBXE (ERC-20)</mat-label>
        <input type="number" matInput [formControl]="form.controls.ethTokenUbxe">
        <mat-hint *ngIf="form.controls.ethTokenUbxe.invalid"></mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>UBXM (ERC-20)</mat-label>
        <input type="number" matInput [formControl]="form.controls.ethTokenUbxm">
        <mat-hint *ngIf="form.controls.ethTokenUbxm.invalid"></mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>UBSN (ERC-20)</mat-label>
        <input type="number" matInput [formControl]="form.controls.ethTokenUbsn">
        <mat-hint *ngIf="form.controls.ethTokenUbsn.invalid"></mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>UBXP (ERC-20)</mat-label>
        <input type="number" matInput [formControl]="form.controls.ethTokenUbxp">
        <mat-hint *ngIf="form.controls.ethTokenUbxp.invalid"></mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>UBX4T (ERC-20)</mat-label>
        <input type="number" matInput [formControl]="form.controls.ethTokenUbx4t">
        <mat-hint *ngIf="form.controls.ethTokenUbx4t.invalid"></mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>UBXCF (ERC-20)</mat-label>
        <input type="number" matInput [formControl]="form.controls.ethTokenUbxcf">
        <mat-hint *ngIf="form.controls.ethTokenUbxcf.invalid"></mat-hint>
      </mat-form-field>
    </mat-card-content>
  </mat-card>

  <br/>

  <mat-card>
    <mat-card-content>
      <h3>UBIX</h3>
      <mat-form-field>
        <mat-label>UBХ (native)</mat-label>
        <input type="number" matInput [formControl]="form.controls.ubixUbx">
        <mat-hint *ngIf="form.controls.ubixUbx.invalid"></mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>CDR (UBХ) (UBCDR)</mat-label>
        <input type="number" matInput [formControl]="form.controls.ubixTokenCdr">
        <mat-hint *ngIf="form.controls.ubixTokenCdr.invalid"></mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>LSE (UBХ) (UBXE)</mat-label>
        <input type="number" matInput [formControl]="form.controls.ubixTokenLse">
        <mat-hint *ngIf="form.controls.ubixTokenLse.invalid"></mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>MMM (UBХ) (UBXM)</mat-label>
        <input type="number" matInput [formControl]="form.controls.ubixTokenMmm">
        <mat-hint *ngIf="form.controls.ubixTokenMmm.invalid"></mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>UBSN (UBХ)</mat-label>
        <input type="number" matInput [formControl]="form.controls.ubixTokenUbsn">
        <mat-hint *ngIf="form.controls.ubixTokenUbsn.invalid"></mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>WAF (UBХ) (UBWF)</mat-label>
        <input type="number" matInput [formControl]="form.controls.ubixTokenWaf">
        <mat-hint *ngIf="form.controls.ubixTokenWaf.invalid"></mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>TKS (UBХ) (UBX4T)</mat-label>
        <input type="number" matInput [formControl]="form.controls.ubixTokenTks">
        <mat-hint *ngIf="form.controls.ubixTokenTks.invalid"></mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>CDF (UBХ) (UBPAY)</mat-label>
        <input type="number" matInput [formControl]="form.controls.ubixTokenCdf">
        <mat-hint *ngIf="form.controls.ubixTokenCdf.invalid"></mat-hint>
      </mat-form-field>
    </mat-card-content>
  </mat-card>

  <br/>

  <mat-card>
    <mat-card-content>
      <h3>BNB & ETHEREUM & UBX WALLETS FOR TOKENS</h3>
      <mat-form-field>
        <mat-label>BNB</mat-label>
        <input type="number" matInput [formControl]="form.controls.bnbForBscToken">
        <mat-hint *ngIf="form.controls.bnbForBscToken.invalid"></mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>ETH</mat-label>
        <input type="number" matInput [formControl]="form.controls.ethForEthToken">
        <mat-hint *ngIf="form.controls.ethForEthToken.invalid"></mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>UBX</mat-label>
        <input type="number" matInput [formControl]="form.controls.ubxForUbixToken">
        <mat-hint *ngIf="form.controls.ubxForUbixToken.invalid"></mat-hint>
      </mat-form-field>
    </mat-card-content>
  </mat-card>

  <br/>

  <button mat-button color="primary" aria-label="Request" (click)="makeRequest()"
            [disabled]="form.invalid">Update</button>

  <br/>

</div>
