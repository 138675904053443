<div *ngIf="!mobileDetect.phone()">
  <div class="row header">
  <div class="col-1"></div>
  <div class="col-5">
    <div class="logo-block" (click)="goHome()">
      <div class="rect-1"></div><div class="rect-2"></div><div class="rect-3"></div><div class="rect-4"></div>
      <div class="logo-text">CDR</div>
      <div class="logo-name">Crypto Depositary Receipts</div>
    </div>
  </div>
  <div class="col-5">
    <div class="transactions-block">
      <div class="ubx"></div>
      <div class="transactions-text">{{transactionsNum}} transactions</div>
      <div class="ubx-arrow"></div>
    </div>
  </div>
  <div class="col-1"></div>
  </div>
</div>


<div *ngIf="mobileDetect.phone()" class="navbar-mobile row">
  <div class="row">
    <div class="logo-block-mobile col" (click)="goHome()">
      <div class="logo-mobile">
        <div class="rect-1"></div><div class="rect-2"></div><div class="rect-3"></div><div class="rect-4"></div>
        <div class="logo-text-mobile">CDR</div>
      </div>
      <div class="logo-name-mobile">Crypto Depositary Receipts</div>
    </div>
  </div>
  <div class="row">
    <div class="transactions-block-mobile col">
      <div class="transactions-mobile">
        <div class="ubx"></div>
        <div class="transactions-text">{{transactionsNum}} transactions</div>
        <div class="ubx-arrow"></div>
      </div>
    </div>
  </div>
</div>
