import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {environment} from "../../../../../environments/environment";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit{

  link: string = '/' + environment.adminUrl;
  isAuth = false;

  constructor(private router: Router,
              private authService: AuthService) {
  }

  ngOnInit() {
    this.authService.$userId.next(this.authService.create_UUID());

    this.authService.$isAuth.subscribe(result => {
      this.isAuth = result;
    })
  }

  logout() {
    this.authService.$isAuth.next(false);
    this.router.navigate([this.link, 'login'])
  }
}
