import {Component, ElementRef, ViewChild} from '@angular/core';
import {SelectionModel} from "@angular/cdk/collections";
import {WalletAccessType} from "../../models/wallet-access-type";
import {WalletDto} from "../../models/Wallets/WalletDto";
import {Subject} from "rxjs";
import {MatDialogRef} from "@angular/material/dialog";
import WalletDtoFactory from "../../models/Wallets/WalletDtoFactory";

@Component({template: ''})
export abstract class BaseAccessToWallet {
  @ViewChild('fileUploader') fileUploader: ElementRef;
  selectedAccessType = new SelectionModel<WalletAccessType>(false, []);
  walletAccessType = WalletAccessType;
  isInputPassword = false;
  isAccessTypeSelected = false;
  error: any;
  wallet: WalletDto;
  loading = new Subject();

  protected useCache = false;
  public abstract data: any;

  protected constructor(public dialogRef: MatDialogRef<BaseAccessToWallet>) {
  }

  protected abstract finishAction(): void;

  accessWithPrivateKey(privateKey: any): void {
    try {
      this.wallet = WalletDtoFactory.get(this.data.coinType, privateKey);
      if (this.wallet.getAddress() === this.data.address || this.data.isImport) {
        this.cacheKey();
        this.finishAction();
      }
      else {
        this.dialogRef.close('The Keystore file or Private key is not from this wallet.');
      }
    }
    catch(e) {
      console.error(e);
      this.dialogRef.close(e);
    }
  }

  async accessWithKeyStore(password: any) {
    this.loading.next(true);
    try {
      await this.wallet.decrypt(password);
    }
    catch {
      this.loading.next(false);
      this.error = 'Password is incorrect';
      return;
    }

    if (this.wallet.getAddress() === this.data.address || this.data.isImport) {
      this.cacheKey();
      this.finishAction();
    }
    else {
      this.dialogRef.close('The Keystore file or Private key is not from this wallet.');
    }
    this.loading.next(false);
  }

  getAccess() {
    if (this.selectedAccessType.isSelected(WalletAccessType.PrivateKey)) {
      this.isAccessTypeSelected = true;
    }
    else {
      this.fileUploader.nativeElement.click();
    }
  }

  onFileChange(event: any) {
    this.error = null;

    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      this.loading.next(true);
      const [file] = event.target.files;
      reader.readAsText(file);

      reader.onload = async () => {
        try {
          let wallet = WalletDtoFactory.get(this.data.coinType, null, reader.result.toString());
          if (this.data.coinType == 'Cil')
            try {
              if (wallet.hasHash && !await wallet.checkHash())
                throw new Error('The Keystore file was changed');
            }
            catch(e) {
              this.loading.next(false);
              this.error = e;
              return;
            }
          this.wallet = wallet;

          this.isInputPassword = true;
          this.isAccessTypeSelected = true;
        }
        catch(e) {
          this.dialogRef.close(e);
        }
        this.loading.next(false);
      };
    }
  }

  cacheKey() {
    if (this.useCache && this.wallet) {
      sessionStorage.setItem('wkp', this.wallet.getPrivateKey());
    }
  }

  closeClick(): void {
    this.dialogRef.close();
  }
}
