import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {LayoutComponent} from "./layout/layout.component";
import {SendComponent} from "./send/send.component";
import {SendConfirmComponent} from "./send-confirm/send-confirm.component";
import {CreateComponent} from "./create/create.component";
import {SwapComponent} from "./swap/swap.component";
import {LandingComponent} from "./landing/landing.component";

const routes: Routes = [
  {
    path: '',
    component: LandingComponent
  },
  {
    path: 'exchange',
    component: LayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: CreateComponent
      },
      {
        path: 'swap/:transitWallet',
        pathMatch: 'full',
        component: SwapComponent
      },
      {
        path: 'send/:transitWallet',
        pathMatch: 'full',
        component: SendComponent
      },
      {
        path: 'send-confirm/:transitWallet',
        pathMatch: 'full',
        component: SendConfirmComponent
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SwapRoutingModule {
}
