<app-spinner></app-spinner>

<div *ngIf="!mobileDetect.phone()">
  <div class="container">
    <app-header></app-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
    <app-support></app-support>
  </div>
</div>

<div *ngIf="mobileDetect.phone()">
  <div class="container-fluid" [ngClass]="{'container-fluid-mobile': mobileDetect.phone()}">
    <app-header></app-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
    <app-support></app-support>
  </div>
</div>




