<div *ngIf="!mobileDetect.phone()">
  <div class="my-footer">
    <div class="row">
      <div class="line line-desktop col-10 offset-1"></div>
    </div>
    <div class="row">
      <div class="chat">
        <div class="header-text">
          Chat with community
        </div>
        <div class="telegram" (click)="goTelegram()" matTooltip="Open Telegram @CDR_PM" matTooltipPosition="right"></div>
      </div>
    </div>
    <div class="row">
      <div class="chat">
        <div class="header-text-10">
          E-Mail <a href="mailto:token@ubikiri.com">token@ubikiri.com</a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col footer-text">
        Copyright © 2023 UBIX Network<br/>
        All rights reserved. United Arab Emirates
      </div>
    </div>
    <div class="row">
      <div class="chat">
        <div class="header-text">
          Developed by
        </div>
        <div class="ubix-network" (click)="goUbixNetwork()" matTooltip="Open Ubix.Network" matTooltipPosition="right"></div>
      </div>
    </div>
    <div class="row">
      <div class="chat">
        <div class="header-text-10">
          <a href="/assets/pdf/CDR_eng.pdf" target="_blank">Manual (ENG)</a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="space"></div>
    </div>
  </div>
</div>

<div *ngIf="mobileDetect.phone()">
    <div class="row">
      <div class="line col-10 offset-1"></div>
    </div>
    <div class="row">
      <div class="chat">
        <div class="header-text header-text-mobile">
          Chat with community
        </div>
        <div class="telegram" (click)="goTelegram()" matTooltip="Open Telegram" matTooltipPosition="right"></div>
      </div>
    </div>
  <div class="row">
    <div class="chat">
      <div class="header-text-10 header-text-mobile">
        E-Mail <a href="mailto:token@ubikiri.com">token@ubikiri.com</a>
      </div>
    </div>
  </div>
    <div class="row">
      <div class="col footer-text footer-text-mobile">
        Copyright © 2023 UBIX Network<br/>
        All rights reserved. United Arab Emirates
      </div>
    </div>
    <div class="row">
      <div class="chat">
        <div class="header-text header-text-mobile">
          Developed by
        </div>
        <div class="ubix-network" (click)="goUbixNetwork()" matTooltip="Open Ubix.Network" matTooltipPosition="right"></div>
      </div>
    </div>
  <div class="row">
    <div class="chat">
      <div class="header-text-10 header-text-mobile">
        <a href="/assets/pdf/CDR_eng.pdf" target="_blank">Manual (ENG)</a>
      </div>
    </div>
  </div>
    <div class="row">
      <div class="space"></div>
    </div>
</div>
