<div class="container">
  <h1 class="container-right-header">Send</h1>
  <div *ngIf="transitWallet">
    <form [formGroup]="form" (submit)="createTransaction()" >
      <div class="row">
        <div class="column">
          <p class="text">Send {{transitWallet.pair.fromDisplayName}}</p>
          <mat-form-field class="input-field">
            <mat-label>Amount</mat-label>
            <input type="text" matInput formControlName="amount">
            <mat-error *ngIf="form.controls.amount.hasError('required')">
              Amount is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="!form.controls.amount.hasError('required') &&
                          form.controls.amount.hasError('min')">
              Minimum amount is {{this.transitWallet.rate.minAmountWithFee}}.
            </mat-error>
          </mat-form-field>
          <mat-form-field class="input-field">
            <mat-label>Wallet from send {{transitWallet.pair.fromDisplayName}}</mat-label>
            <input type="text" matInput formControlName="fromAddress">
          </mat-form-field>
        </div>
        <div class="column">
          <p class="text">Receive {{transitWallet.pair.toDisplayName}}</p>
          <mat-form-field class="input-field">
            <mat-label>Wallet to receive {{transitWallet.pair.toDisplayName}}</mat-label>
            <input type="text" matInput formControlName="transitAddress">
          </mat-form-field>
        </div>
      </div>

      <button submit color="primary" class="btn">Continue</button>
    </form>

  </div>

</div>
