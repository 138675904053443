<div class="support">
  <mat-menu
    #chat="matMenu"
    [hasBackdrop]="false"
    [overlapTrigger]="false"
    xPosition="before"
    yPosition="below"
    class="support__panel support__chatMenu">
    <div class="support__menuInner" (click)="$event.stopPropagation()">
      <app-support-chat (createDialog)="createDialog()"
                        (onClose)="closeChat()"
                        (sendMessage)="sendMessage(activeChatId, $event)"
                        [activeDialog]="activeChatDialog"
                        [dialogMessages]="messages"
                        [currentUser]="isUserEmail"
                        [loadingProgress]="loadingProgress"
                        [sending$]="sending$"
                        [messagesSent$]="messagesSent$"
      ></app-support-chat>
    </div>
  </mat-menu>

  <div
    #supportTrigger="matMenuTrigger"
    mat-button
    [matMenuTriggerFor]="menu"
    class="support__trigger support__panel">
    <img src="/assets/icons/support.svg" alt="Support">
<!--    class="support__trigger">-->
<!--    <img src="/assets/svg/icons/support/comment_gradient.svg" alt="Support">-->

    <div *ngIf="newMessages | async" class="support__unread support__unread--floating">
      {{newMessages | async}}
<!--    <span>Support chat</span>-->

<!--    <div *ngIf="newMessages|async" class="support__unread support__unread&#45;&#45;floating">-->
<!--      {{newMessages|async}}-->
    </div>
  </div>
  <mat-menu
    #menu="matMenu"
    [hasBackdrop]="false"
    yPosition="above"
    xPosition="before"
    class="support__dialogsMenu support__panel">
    <div
      class="support__menuInner"
      (click)="$event.stopPropagation()">

      <div
        class="support__chatTrigger"
        #chatTrigger="matMenuTrigger"
        [matMenuTriggerFor]="chat">
      </div>

      <div class="support__title">Your dialogues</div>

      <button *ngIf="isAuthenticated | async else auth" class="support__btn" (click)="openChat(null)">
        <img src="/assets/icons/plus.svg" alt="+">
        Start a new chat
      </button>
      <ng-template #auth>
        <div style="padding-top: 10px; padding-bottom: 20px" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
          <div fxLayout="column" fxLayoutGap="5px">
            <div>
<!--              <mat-form-field>-->
                <mat-label>Email:</mat-label>
                <input matInput [formControl]="formControlEmail" class="support-input-field"
                       [ngClass]="{'support-input-field-red': (formControlEmail.invalid && formControlEmail.touched)}">
<!--              </mat-form-field>-->
            </div>
            <div>
<!--              <mat-form-field>-->
                <mat-label>Password:</mat-label>
                <input matInput type="password" [formControl]="formControlPassword"  class="support-input-field"
                       [ngClass]="{'support-input-field-red': (formControlPassword.invalid && formControlPassword.touched)}">
<!--              </mat-form-field>-->
            </div>
            <div>
              <button class="button-exchange"  (click)="authenticate()"
                      [disabled]="formControlEmail.invalid || formControlPassword.invalid">
                <span class="button-exchange-text">Authenticate</span>
              </button>
            </div>
          </div>

<!--          -->
<!--          -->
<!--          <button mat-stroked-button (click)="authenticate()"-->
<!--                  [disabled]="formControlEmail.invalid || formControlPassword.invalid">-->
<!--            Authenticate-->
<!--          </button>-->
        </div>
      </ng-template>
      <app-support-dialogs
        *ngIf="isAuthenticated|async"
        [dialogs]="dialogs"
        [activeDialog]="activeChatId"
        (onDialogClick)="openChat($event)">
      </app-support-dialogs>

      <div (click)="closeDialogs()" class="support__close">
        <img class="support__mail" src="/assets/icons/mail.svg" alt="">
        <span>Support Chat</span>
        <img src="/assets/icons/arrow.svg" alt="▼">
      </div>
    </div>
  </mat-menu>
</div>
