import { Component } from '@angular/core';
import {SpinnerService} from "../../services/spinner.service";

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  showSpinner = false;
  constructor(private spinnerService: SpinnerService) {
    spinnerService.$showSpinner.subscribe(data => this.showSpinner = data);
  }
}
